import React, { MouseEvent, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import KrgButton, {
  SIZE_ENUM as BUTTON_SIZE_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
} from "@kargo/shared-components.krg-button";
import { BACKGROUND_ENUM } from "@kargo/shared-components.krg-shared";

import { PATHS } from "../../../constants/paths";
import { FormActionsEnum, FormInitInputs } from "../../../@types/form.types";
import { useFormContext } from "../../../context/form/form.provider";
import ConfirmModal from "../../../components/Modals/ConfirmModal";

type SingleMediaActionButtonsProps = {
  submitHandler: (ev: MouseEvent) => void;
  initialFormState: FormInitInputs;
};

const SingleMediaActionButtons = ({
  initialFormState,
  submitHandler,
}: SingleMediaActionButtonsProps) => {
  const { push } = useHistory();
  const [{ errors, touched }, dispatch] = useFormContext();
  const [showModal, setShowModal] = useState(false);

  const cancelAction = useCallback(() => {
    if (touched) {
      setShowModal(true);
    } else {
      push(PATHS.MEDIA_GALLERY);
    }
  }, [touched]);

  const handleConfirmReset = () => {
    dispatch({
      type: FormActionsEnum.RESET_FORM,
      payload: initialFormState,
    });
    setShowModal(false);
  };

  return (
    <Box
      sx={{
        marginBottom: "45px",
      }}
    >
      <ConfirmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmitClick={() => handleConfirmReset()}
        header="Reset Applied Chages"
        body="Are you sure you want to reset changes you applied for this media?"
        submitButtonText="Reset Form Fields"
        cancelButtonText="Cancel"
      />

      <Grid container columnGap={3}>
        <Grid item xs={6}>
          <Typography className="page-subtitle">Media Details</Typography>
        </Grid>
        <Grid item xs textAlign="right">
          <Stack direction="row" spacing={2} justifyContent="end">
            <KrgButton
              id="cancel"
              className="form-button"
              text="Cancel"
              onClick={cancelAction}
              variant={BUTTON_VARIANT_ENUM.outlined}
              size={BUTTON_SIZE_ENUM.small}
              isSquaredBorder
            />
            <KrgButton
              id="submit-update"
              className="form-button"
              text="Save Changes"
              onClick={submitHandler}
              variant={BUTTON_VARIANT_ENUM.contained}
              size={BUTTON_SIZE_ENUM.small}
              isSquaredBorder
              isEnabled={Object.keys(errors).length === 0 && touched}
              background={BACKGROUND_ENUM.black}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SingleMediaActionButtons;
