import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import KrgButton, {
  THEME_ENUM as BUTTON_THEME_ENUM,
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import KrgTooltip from "@kargo/shared-components.krg-tooltip";
import { useHistory } from "react-router-dom";
import { PlaylistInput } from "../../@types/playlist.types";
import usePlaylists from "../../hooks/usePlaylists";
import useSnackbar from "../../hooks/useSnackbar";
import useVideos from "../../hooks/useVideos";
import VideoDropzone from "../../components/VideoDropzone";
import { VideoCard } from "../../components/VideoCard";
import VideoModal from "../../components/Modals/VideoModal";
import TrackedKrgButton from "../../components/TrackedButton/TrackedKrgButton";
import getDuplicateName from "../../helpers/getDuplicateName";
import { PATHS } from "../../constants/paths";

const useStyles = makeStyles(() => ({
  textButton: {
    textTransform: "none",
    textDecoration: "underline",
  },
}));

type Props = {
  onClose: () => void;
  onSubmit: (data: PlaylistInput) => void;
  username: string;
  publisherGroup: string;
};

export default function CreatePlaylistForm({
  onClose,
  onSubmit,
  username,
  publisherGroup,
}: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { snackbarSuccess, snackbarError } = useSnackbar();
  const [title, setTitle] = React.useState("");
  const { playlists } = usePlaylists({});
  const [playlist, setPlaylist] = React.useState<PlaylistInput>({
    medias: [],
  });
  const [modalVideoIndex, setModalVideoIndex] = React.useState(-1);
  const [playlistCreateError, setPlaylistCreateError] = React.useState("");
  const defaultCreateError = "Playlist title can't be empty";
  const modalVideo = playlist.medias[modalVideoIndex];
  const totalVideos = playlist.medias.length;
  const { api } = usePlaylists();
  const { api: videosAPI } = useVideos({});

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const placeholderTitle = getPlaceholderTitle(playlists || [], "New Playlist");

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      const processedFiles = [];
      const uploadedVideos = [];
      acceptedFiles.forEach((file) => {
        if (processedFiles.includes(file)) return;
        processedFiles.push(file);
        videosAPI.create(
          {
            file,
            ...(publisherGroup && { publisherUsername: publisherGroup }),
            onCreate: ({ token }) => {
              uploadedVideos.push(token);
              setPlaylist({ ...playlist, medias: uploadedVideos });
            },
          },
          {
            onSuccess: () => {
              if (processedFiles.length === acceptedFiles.length) {
                snackbarSuccess(
                  `Video${acceptedFiles.length > 1 ? "s" : ""} Added to Playlist`,
                );
              }
            },
          },
        );
      });
    },
    [publisherGroup],
  );
  const handleSubmit = () => {
    if (!title || title?.trim() === "") {
      setPlaylistCreateError(defaultCreateError);
      snackbarError(defaultCreateError);
      return;
    }
    setPlaylistCreateError("");
    api.create({ ...playlist, username, title });
    onSubmit({ ...playlist, title });
  };
  const handleClose = () => {
    setPlaylist({});
    onClose();
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid black",
          padding: 0,
        }}
      >
        <Box sx={{ justifyContent: "center", padding: "21px 10px 10px" }}>
          <KrgTextInput
            theme={KrgTextInput.THEME_ENUM.v2}
            style={{ height: "auto" }}
            errorMessage={playlistCreateError}
            label={placeholderTitle}
            text={title}
            onDebounceEnd={setTitle}
            onTextChange={setTitle}
            debounceTime={0}
          />
        </Box>
        <Box>
          <TrackedKrgButton
            id="save-new-playlist"
            text="Save Playlist"
            variant={BUTTON_VARIANT_ENUM.contained}
            size={BUTTON_SIZE_ENUM.small}
            onClick={handleSubmit}
          />
          <IconButton onClick={handleClose}>
            <KrgTooltip
              tooltip="Cancel playlist creation"
              theme={KrgTooltip.THEME_ENUM.v2}
            >
              <CloseIcon sx={{ color: "#303237", fontSize: "20px" }} />
            </KrgTooltip>
          </IconButton>
        </Box>
      </Box>
      <Box sx={{ padding: "13px" }}>
        <VideoModal
          video={modalVideo}
          isOpen={modalVideoIndex > -1}
          onClose={() => setModalVideoIndex(-1)}
          onNavigatePrev={() =>
            setModalVideoIndex(
              (modalVideoIndex - 1 + totalVideos) % totalVideos,
            )
          }
          onNavigateNext={() =>
            setModalVideoIndex((modalVideoIndex + 1) % totalVideos)
          }
        />
        <Box display="flex">
          {playlist.medias.map((token, i) => (
            <React.Fragment key={token}>
              <VideoCard
                video={{ token }}
                onClick={() => setModalVideoIndex(i)}
              />
            </React.Fragment>
          ))}
        </Box>
        <VideoDropzone onDrop={onDrop}>
          <KrgButton
            theme={BUTTON_THEME_ENUM.v2}
            variant={BUTTON_VARIANT_ENUM.text}
            textContainerClassName={classes.textButton}
            onClick={(e) => {
              e.stopPropagation();
              if (!title || title?.trim() === "") {
                setTitle(placeholderTitle);
              }
              api.create({ ...playlist, title: title || placeholderTitle });
              onClose();
              history.push(`${PATHS.MEDIA_GALLERY}?tab=0`);
            }}
            text="or browse My Library"
          />
        </VideoDropzone>
      </Box>
    </Box>
  );
}

function getPlaceholderTitle(list: any[], placeholder: string) {
  let pTitle = placeholder;
  list.forEach((item) => {
    if (item.title?.includes(placeholder)) {
      pTitle = item.title;
    }
  });
  return getDuplicateName(pTitle);
}
