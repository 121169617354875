import React, { useState } from "react";
import {
  VARIANT_ENUM as BUTTON_VARIANT_ENUM,
  SIZE_ENUM as BUTTON_SIZE_ENUM,
} from "@kargo/shared-components.krg-button";
import { Box } from "@mui/material";
import _, { debounce } from "lodash";
import PlayersTable from "../../components/PlayersTable";
import { useDomains } from "../../hooks/useDomains";
import { Config, Domain } from "../../api/types";
import CreatePropertyModal from "../../components/Modals/CreatePropertyModal";
import useSnackbar from "../../hooks/useSnackbar";
import PropertiesList from "../../components/PropertiesList";
import LoadingCircle from "../../components/LoadingCircle";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import tracker from "../../helpers/tracker";
import TrackedKrgButton from "../../components/TrackedButton/TrackedKrgButton";
import { normalizeUrl } from "../../helpers/validateUrl";

import SearchDropdown from "../../components/SearchDropdown";

export default function PublisherHub(
  { asAdmin, slug }: { asAdmin: boolean; slug: string } = {
    asAdmin: false,
    slug: "",
  },
) {
  const { snackbarSuccess, snackbarError } = useSnackbar();
  const { publisherUsername, publisherSlug } = usePublisherContext(asAdmin);
  const padding = "20px";
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const query = asAdmin && { slug: publisherSlug || slug };
  const {
    domains,
    query: domainsQuery,
    ...domainsAPI
  } = useDomains({ enabled: true, query });

  const [selectedDomain, setSelectedDomain] = React.useState<Domain>();
  if (!selectedDomain && domains && domains.length > 0) {
    setSelectedDomain(domains[0]);
  }
  const [search, setSearch] = React.useState("");
  const [isDomainSelected, setIsDomainSelected] = React.useState(false);
  const [isCreatingConfig, setIsCreatingConfig] = React.useState(false);
  const [isEditingConfig, setIsEditingConfig] = React.useState(false);
  const [updatedSelectedDomain, setUpdatedSelectedDomain] =
    React.useState<Domain>();
  const [isDomainLoading, setIsDomainLoading] = React.useState(false);
  React.useEffect(() => {
    if (domains) setIsDomainLoading(false);
  }, [domains]);
  React.useEffect(() => {
    if (domains?.length) {
      const selectedDomainData = domains.find(
        (d) => d.token === selectedDomain.token,
      );
      if (selectedDomainData) {
        setUpdatedSelectedDomain(_.cloneDeep(selectedDomainData));
      } else {
        setSelectedDomain(domains[0]);
      }
    }
  }, [domains, selectedDomain]);
  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );

  function targetingUrlManager(newConfig: Config, oldConfig: Config) {
    if (newConfig.config.targeting_urls) {
      const oldUrls = oldConfig.config.targeting_urls || [];
      const targetingUrls = newConfig.config.targeting_urls
        .filter((url: string) => {
          if (!oldUrls.includes(url)) return url;
          return false;
        })
        .map((url: string) => normalizeUrl(url));
      const deletedUrls: string[] = newConfig.config.deletedUrls || [];
      if (deletedUrls.length) {
        deletedUrls.forEach((url) => {
          domainsAPI.deleteTargetingUrl(
            {
              url,
              token: selectedDomain.token,
              configToken: newConfig.token,
            },
            {
              onSettled: () => {
                snackbarSuccess(`URL(s) deleted to ${newConfig.key} Player`);
              },
            },
          );
        });
      }
      if (targetingUrls.length) {
        domainsAPI.saveTargetingUrls(
          {
            targeting_urls: targetingUrls,
            domain: selectedDomain.domain,
            token: selectedDomain.token,
            configToken: newConfig.token,
          },
          {
            onSettled: () => {
              snackbarSuccess(`URL(s) added to ${newConfig.key} Player`);
            },
          },
        );
      }
    }
  }

  React.useEffect(() => {
    if (search) {
      debouncedTrack("Search", {
        resource: "property",
        text: search,
      });
    } else {
      setIsDomainSelected(false);
    }
  }, [search]);
  /* eslint-disable-next-line @typescript-eslint/no-shadow */
  const filterDomainList = (selectedDomain: Domain) => {
    if (!isDomainSelected) return domains;
    return domains.filter((domain) =>
      domain?.domain
        .toLowerCase()
        .startsWith(selectedDomain?.domain?.toLowerCase()),
    );
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newInput,
  ) => setSearch(newInput);
  if (!domains && !domainsQuery.isFetched) {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100vh - 70px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingCircle />
      </div>
    );
  }

  return (
    <Box padding={padding}>
      <SearchDropdown
        searchValue={search}
        widthSize="minWidth"
        placeholder="search property"
        options={domains?.map((d) => d.domain)}
        onSearchChange={handleSearchChange}
        onOptionClick={(value: string) => {
          setSelectedDomain(
            domains?.find(
              (d) => d?.domain?.toLowerCase() === value?.toLowerCase(),
            ),
          );
          setIsDomainSelected(true);
        }}
      />
      <CreatePropertyModal
        username={publisherUsername}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        onSubmit={() => setModalIsOpen(false)}
        createOptions={{
          onSuccess: () => {
            snackbarSuccess("Created New Property");
          },
          onError: (error) => {
            console.error(error);
            snackbarError(
              "Error creating property, check the console for more information.",
            );
          },
        }}
      />
      <Box display="flex" flexDirection="column" justifyContent="center">
        <p style={{ fontSize: "10px", fontWeight: 500 }}>
          Select a property from the list to see the Video Player details below
        </p>
        <h2
          style={{
            fontSize: "18px",
            fontWeight: 500,
            marginTop: "30px",
            marginBottom: "12px",
          }}
        >
          Property Field
        </h2>
        <PropertiesList
          properties={filterDomainList(selectedDomain)}
          selected={selectedDomain}
          onSelected={setSelectedDomain}
          onCreateButtonClick={() => setModalIsOpen(true)}
        />
        {domains?.length > 0 && (
          <Box marginTop="40px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginBottom="24px"
            >
              <h2 style={{ fontSize: "18px", fontWeight: 500 }}>
                Video Players
              </h2>
              <Box display="flex" gap="16px">
                {isDomainLoading && (
                  <LoadingCircle style={{ width: "28px", height: "28px" }} />
                )}
                {isEditingConfig ? (
                  <>
                    <TrackedKrgButton
                      id="cancel-edit-players"
                      text="Cancel"
                      size={BUTTON_SIZE_ENUM.small}
                      variant={BUTTON_VARIANT_ENUM.outlined}
                      onClick={() => {
                        // @ts-ignore
                        setUpdatedSelectedDomain();
                        setIsEditingConfig(false);
                      }}
                    />
                    <TrackedKrgButton
                      id="confirm-edit-players"
                      isEnabled={!isDomainLoading}
                      text="Save Changes"
                      size={BUTTON_SIZE_ENUM.small}
                      variant={BUTTON_VARIANT_ENUM.outlined}
                      onClick={() => {
                        setIsDomainLoading(true);
                        updatedSelectedDomain.configs.forEach((newConfig) => {
                          const oldConfig = selectedDomain.configs.find(
                            (c) => c.token === newConfig.token,
                          );
                          if (_.isEqual(oldConfig, newConfig)) {
                            return;
                          }
                          targetingUrlManager(newConfig, oldConfig);

                          const config = { ...newConfig };
                          delete config.config.targeting_urls;
                          delete config.config.deletedUrls;

                          domainsAPI.updateConfig({
                            domain: selectedDomain.token,
                            config,
                          });
                        });
                        selectedDomain.configs = updatedSelectedDomain.configs;
                        setIsEditingConfig(false);
                      }}
                    />
                  </>
                ) : (
                  <TrackedKrgButton
                    id="edit-players"
                    text="Edit Video Player"
                    isEnabled={!isDomainLoading}
                    onClick={() => {
                      setIsEditingConfig(true);
                    }}
                    size={BUTTON_SIZE_ENUM.small}
                    variant={BUTTON_VARIANT_ENUM.outlined}
                  />
                )}
                <TrackedKrgButton
                  id="add-player"
                  text="Add Video Player"
                  isEnabled={!isEditingConfig && !isDomainLoading}
                  onClick={() => setIsCreatingConfig(true)}
                  size={BUTTON_SIZE_ENUM.small}
                  variant={BUTTON_VARIANT_ENUM.contained}
                />
              </Box>
            </Box>
            {selectedDomain && (
              <Box sx={{ opacity: isDomainLoading ? 0.7 : 1 }}>
                <PlayersTable
                  asAdmin={asAdmin}
                  query={query}
                  domain={
                    updatedSelectedDomain ||
                    domains.find((d) => d.token === selectedDomain.token)
                  }
                  search={search}
                  isEditing={isEditingConfig}
                  isCreating={isCreatingConfig}
                  onUpdate={setUpdatedSelectedDomain}
                  setConfigUpdatingStatus={setIsDomainLoading}
                  onCreateFinished={(newDomain) => {
                    if (newDomain) {
                      newDomain.configs.reverse();
                      selectedDomain.configs = newDomain.configs;
                    }
                    setIsCreatingConfig(false);
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}
