import { useQuery } from "react-query";
import useAPI from "../api/useAPI";

export default function useTags(inputQuery: Record<string, any> = {}) {
  const { api, didLoad } = useAPI();

  const query = useQuery(
    ["tags", inputQuery],
    async () => {
      if (!api) {
        throw new Error("API not initialized");
      }
      try {
        const response = await api.getTags(inputQuery);
        return response;
      } catch (error) {
        console.error("Error fetching tags:", error);
        throw new Error("Failed to fetch tags");
      }
    },
    {
      enabled: didLoad,
      staleTime: Infinity,
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  );

  return {
    query,
    tags: query.data?.tags || [],
    isLoading: query.isLoading,
    isError: query.isError,
    refetch: query.refetch,
  };
}
