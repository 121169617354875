import React from "react";
import { makeStyles } from "@mui/styles";
import AutoComplete from "../AutoComplete/AutoComplete";
import useTags from "../../hooks/useTags";
import { AutocompleteProps } from "../../@types/media.types";
import { usePublisherContext } from "../../hooks/usePublisherProvider";

const useStyles = makeStyles({
  autocomplete: {
    minHeight: "37px",
    "& .MuiTextField-root": {
      minWidth: "260px",
      maxWidth: "320px",
      height: "37px",
    },
    "& span.MuiAutocomplete-tag": {
      fontSize: "small",
    },
  },
});

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: AutocompleteProps[];
  onChange?: (tag: AutocompleteProps[]) => void;
  className?: string;
};

export default function TagSelect({
  asAdmin,
  isEnabled = true,
  value = [],
  onChange,
  className,
}: Props) {
  const classes = useStyles();
  const { publisherSlug } = usePublisherContext(asAdmin);
  const query = {
    ...(publisherSlug && { slug: publisherSlug }),
  };
  const { tags, isLoading } = useTags(query);
  return (
    <AutoComplete
      id="tags-select"
      className={`${className} ${classes.autocomplete}`}
      isEnabled={isEnabled}
      label="Tags"
      placeholder="Add"
      isLoading={isLoading}
      value={value}
      onChange={onChange}
      options={tags.map((tag, id) => ({ id, name: tag }))}
      isOptionEqualToValue={(option, val) => option.name === val.name}
    />
  );
}
