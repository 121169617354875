import { UploadContextState } from "../@types/upload.types";

export const VIDEO_CREATE_MUTATION_KEY = `video-create-mutation`;

// Avoid: Linter: ESLint: Dependency cycle detected.(import/ no-cycle)
// if import is used in several files.
export const uploadProcessInitialState: UploadContextState = {
  uploadingVideos: [],
  abortController: new AbortController(),
  uploadingFilesCount: 0,
  uploadInProgress: false, // initially always has to be false
};
