import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { CheckCircle, Close, ExpandLess, Movie } from "@mui/icons-material";

import "./styles.scss";
import {
  useUploadDispatchContext,
  useUploadStateContext,
} from "../../context/upload/upload.provider";
import {
  UploadActionEnums,
  UploadingItemType,
} from "../../@types/upload.types";
import ConfirmModal from "./ConfirmModal";

const useStyles = makeStyles({
  paper: {
    "& .MuiPaper-root": {
      padding: 0,
      position: "fixed",
      bottom: "10px",
      right: "10px",
      width: "440px",
      maxWidth: "100%",
      "& .MuiCollapse-root .MuiCollapse-wrapperInner > div": {
        padding: 0,
      },
      "& .MuiAccordionSummary-content, & .MuiListItem-root": {
        padding: 0,
        margin: "10px 0",
      },
      "& #accordion-panel-header": {
        "&:hover": {
          cursor: "auto",
        },
        "& .MuiAccordionSummary-content": {
          padding: 0,
        },
        "& .Mui-expanded": {
          transform: "rotate(0)",
        },
        "& .Mui-expanded .accordion-expander": {
          transform: "rotate(180deg)",
        },
      },
      "& #accordion-button-summary-container": {
        cursor: "pointer",
        "& > svg": {
          marginLeft: "10px",
        },
      },
      "& .MuiListItemIcon-root": {
        minWidth: "auto",
      },
      "& .media-title-text": {
        padding: "0 10px",
      },
      "& .title": {
        display: "block",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "24px",
      },
      "& .subtitle": {
        display: "block",
        color: "#3C61F3",
        fontSize: "14px",
        lineHeight: "20px",
      },
      "& .prefix-icon": {
        color: "#000000",
      },
    },
  },
});

/**
 * UploadProgressModal
 * @constructor
 */
const UploadProgressModal = () => {
  const classes = useStyles(useStyles);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [counters, setCounter] = useState<{
    completed: number;
    uploading: number;
  }>({ completed: 0, uploading: 0 });
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const uploadState = useUploadStateContext();
  const {
    uploadingVideos,
    abortController,
    uploadingFilesCount,
    uploadInProgress,
  } = uploadState;
  const uploadDispatcher = useUploadDispatchContext();

  useEffect(() => {
    const completed = uploadingVideos.filter((up) => up.completed).length;
    setCounter({
      completed,
      uploading: uploadingVideos.filter((up) => up.isUploading).length,
    });
    if (completed === uploadingFilesCount && !uploadInProgress) {
      uploadDispatcher({
        type: UploadActionEnums.CLOSE_UPLOAD_INDICATOR_CONTAINER,
      });
    }
  }, [uploadState]);

  const toggleAccordion = () => setExpanded(!expanded);

  const handleCancelledUpload = (ev) => {
    ev.preventDefault();
    if (
      uploadInProgress &&
      abortController &&
      !abortController.signal?.aborted
    ) {
      abortController.abort();
      uploadingVideos.forEach((up) => {
        if (!up.completed) {
          uploadDispatcher({ type: UploadActionEnums.UPLOAD_ABORTED });
        }
      });
    }
  };

  const progressIcon = (upload: UploadingItemType, fontSize = "24px") => {
    if (upload.isUploading && upload.progress < 100) {
      return (
        <CircularProgress
          variant="determinate"
          size={fontSize}
          value={upload.progress}
        />
      );
    }
    if (upload.completed) {
      return <CheckCircle sx={{ color: "#006300", fontSize }} />;
    }
    return <CircularProgress size={fontSize} />;
  };

  return (
    <Paper id="upload-progress-container" className={`${classes.paper}`}>
      <ConfirmModal
        isOpen={showConfirmModal}
        header="Are you sure you want to abort uploading videos?"
        body="This will stop uploading videos to the server."
        submitButtonText="Cancel Uploads"
        onClose={() => setShowConfirmModal(false)}
        onSubmitClick={handleCancelledUpload}
      />
      <Accordion defaultExpanded square expanded={expanded}>
        <AccordionSummary
          expandIcon={
            counters.uploading === 0 ? (
              <></>
            ) : (
              <Box id="accordion-button-summary-container">
                <ExpandLess
                  className="accordion-expander"
                  onClick={() => toggleAccordion()}
                />
                <Close onClick={() => setShowConfirmModal(true)} />
              </Box>
            )
          }
          aria-controls="accordion-panel-content"
          id="accordion-panel-header"
        >
          <Box>
            <Typography className="title" variant="h5">
              Uploading {uploadingFilesCount} video(-s)
            </Typography>
            <Typography className="subtitle" variant="subtitle1">
              {counters.completed} out of {uploadingFilesCount}
            </Typography>
          </Box>
        </AccordionSummary>
        {uploadingVideos.length > 0 && (
          <>
            <Divider />
            <AccordionDetails>
              <List>
                {uploadingVideos.map((video) => (
                  <ListItem
                    key={`uploaded-video-progress-${video.title || video.token}`}
                  >
                    <ListItemIcon className="prefix-icon">
                      <Movie fontSize="small" />
                    </ListItemIcon>
                    <ListItemText className="media-title-text">
                      {video.title}
                    </ListItemText>
                    <ListItemIcon>{progressIcon(video)}</ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </>
        )}
      </Accordion>
    </Paper>
  );
};

export default UploadProgressModal;
