import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import useSnackbar from "./useSnackbar";
import { PlaylistInput, PlaylistResponse } from "../@types/playlist.types";

export default function usePlaylist(token: string, initialData?: any) {
  const { snackbarError, snackbarSuccess } = useSnackbar();
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const query = useQuery({
    queryKey: ["playlist", token],
    queryFn: () =>
      token && api.Playlist.get(token).then((data) => data.playlist),
    initialData,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!token && didLoad,
  });

  const getStreamableVideos = useQuery({
    queryKey: ["playlist-streamable", token],
    queryFn: () =>
      token && api.Playlist.medias(token).then((data) => data.medias),
    initialData,
    staleTime: Infinity,
    keepPreviousData: true,
    enabled: !!token && didLoad,
  });

  const updateMutation = useMutation({
    mutationFn: (data: Partial<PlaylistInput>) =>
      api.Playlist.update(token, data),
    onSuccess: ({ playlist }: { playlist: PlaylistResponse }) => {
      snackbarSuccess(`Playlist related information was updated`);
      queryClient.setQueryData(["playlist"], playlist);
      queryClient.invalidateQueries({ queryKey: ["playlist"] });
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while updating the video. Check the console for more information.",
      );
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (tkn: string) => api.Playlist.delete(tkn),
    onSuccess: () => {
      snackbarSuccess(`Video was successfully deleted`);
    },
    onError: (e) => {
      console.error(e);
      snackbarError(
        "Error while deleting the video. Check the console for more information.",
      );
    },
  });

  return {
    ...query,
    playlist: query.data,
    medias: getStreamableVideos.data,
    api: {
      update: updateMutation.mutate,
      delete: deleteMutation.mutate,
    },
    mutations: {
      updateMutation,
      deleteMutation,
    },
  };
}
