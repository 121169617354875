import React from "react";
import { Box } from "@mui/material";
import { PlayArrowRounded } from "@mui/icons-material";

const ThumbnailPlaceholder = () => (
  <Box
    sx={{
      width: "70px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#E5EAEF",
      borderRadius: "5px",
    }}
  >
    <PlayArrowRounded sx={{ color: "#FFFFFF", fontSize: "24px" }} />
  </Box>
);

export default ThumbnailPlaceholder;
