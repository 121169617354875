import React from "react";
import { FormControl, FormGroup, InputLabel } from "@mui/material";

import KrgTextInput, {
  VARIANT_ENUM,
} from "@kargo/shared-components.krg-text-input";

import FieldHinterText from "./FieldHinterText";
import { FormInputFields } from "../../@types/form.types";
import { handleOnChange } from "../../context/form/form.actions";
import { useFormContext } from "../../context/form/form.provider";
import WrongFieldComponent from "./WrongFieldComponent";

const TextInputFieldComponent = ({ fieldKey }: { fieldKey: string }) => {
  const [{ errors, formInputFields }, dispatch] = useFormContext();
  const textField = formInputFields[fieldKey] as FormInputFields;
  const fieldErrors: string[] = errors[fieldKey];

  if (!textField) return <WrongFieldComponent fieldKey={fieldKey} />;

  return (
    <FormGroup
      className={`form-group-container ${fieldErrors ? "error-field-group" : ""}`}
    >
      {textField && <InputLabel>{textField?.label}</InputLabel>}
      <FormControl error={!!fieldErrors}>
        <KrgTextInput
          id="media-title"
          name={textField.name}
          variant={VARIANT_ENUM.outlined}
          text={(textField.value || textField?.defaultValue)?.toString() || ""}
          style={{ height: "auto" }}
          onTextChange={(text) => handleOnChange(dispatch, textField, text)}
        />
        {textField.hinterText && (
          <FieldHinterText text={textField.hinterText} errors={fieldErrors} />
        )}
      </FormControl>
    </FormGroup>
  );
};

export default TextInputFieldComponent;
