import React, {
  createContext,
  useMemo,
  useReducer,
  useContext,
  Context,
  Reducer,
} from "react";

import uploadReducer from "./upload.reducer";
import {
  UploadContextState,
  UploadDispatcher,
  UploadActionTypes,
} from "../../@types/upload.types";
import { uploadProcessInitialState } from "../../constants/provider.states";

// Initial state, as it's shared will have one point of truth, and avoid linter error
const initialState: UploadContextState = uploadProcessInitialState;

// IMPROVEMENT: Use sessionStorage to persist state for pre-signed URLS, and apply file load to that URL

// Create context
const CurrentUploadStateContext: Context<UploadContextState> = createContext(
  null!,
);
const CurrentUploadStateDispatcher: Context<UploadDispatcher> =
  createContext(null);

// Create provider
const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [store, dispatch] = useReducer<
    Reducer<UploadContextState, UploadActionTypes>
  >(uploadReducer, initialState);

  const memorizedValue: UploadContextState = useMemo(() => store, [store]);

  return (
    <CurrentUploadStateContext.Provider value={memorizedValue}>
      <CurrentUploadStateDispatcher.Provider value={dispatch}>
        {children}
      </CurrentUploadStateDispatcher.Provider>
    </CurrentUploadStateContext.Provider>
  );
};

// Custom hooks
export const useUploadStateContext = (): UploadContextState => {
  const context = useContext<UploadContextState>(CurrentUploadStateContext);
  if (!context) {
    throw new Error(
      "useUploadStateContext must be used within a UploadProvider",
    );
  }
  return context;
};

export const useUploadDispatchContext = (): UploadDispatcher => {
  const context = useContext<UploadDispatcher>(CurrentUploadStateDispatcher);
  if (!context) {
    throw new Error(
      "useUploadDispatchContext must be used within a UploadProvider",
    );
  }
  return context;
};

export default AppProvider;
