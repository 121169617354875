import React from "react";
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";
import {
  AccessTime,
  DateRange,
  Error,
  RemoveCircle,
} from "@mui/icons-material";
import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { Video } from "../api/types";
import useVideo from "../hooks/useVideo";
import { getDisplayTitle } from "../helpers/videos";
import ResilientImage from "./ResilientImage";
import { DraggableItem } from "./DraggingProvider/DraggableItem";

dayjs.extend(durationPlugin);

const useStyles = makeStyles({
  checkbox: {
    "& .MuiCheckbox-root": {
      padding: 0,
      transform: "translateX(5px)",
      "&:not(.Mui-checked)": { color: "#91A0AD" },
    },
    "& .MuiCheckbox-root:not(.Mui-checked)": { color: "#91A0AD" },
  },
  progressbar: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    height: "10px",
    borderRadius: "10px",
    backgroundColor: "#E0E3E7",
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#0033FF",
      borderRadius: "10px",
    },
  },
});

function PlayButton() {
  return (
    <img
      src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHZpZXdCb3g9IjAgMCA0MCA0MCIgdmVyc2lvbj0iMS4xIiB5PSIwcHgiIHg9IjBweCIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDQwIDQwIj48Zz48cGF0aCBmaWxsPSJncmF5IiBkPSJtMzcuMyAxMGMtNS41LTkuNi0xNy43LTEyLjgtMjcuMy03LjNzLTEyLjggMTcuNy03LjMgMjcuMyAxNy44IDEyLjggMjcuMyA3LjNjOS42LTUuNSAxMi44LTE3LjcgNy4zLTI3LjN6IiAvPjxwYXRoIGZpbGw9IndoaXRlIiBkPSJtMzcuMyAxMGMtNS41LTkuNi0xNy43LTEyLjgtMjcuMy03LjNzLTEyLjggMTcuNy03LjMgMjcuMyAxNy44IDEyLjggMjcuMyA3LjNjOS42LTUuNSAxMi44LTE3LjcgNy4zLTI3LjN6bS05LjMgMjMuOWMtNy43IDQuNC0xNy40IDEuOC0yMS45LTUuOS00LjQtNy42LTEuNy0xNy40IDUuOS0yMS45IDcuNy00LjQgMTcuNC0xLjggMjEuOSA1LjkgNC40IDcuNyAxLjggMTcuNC01LjkgMjEuOXptLTAuOC0xNC43bC0xMC41LTYuMWMtMS4yLTAuNC0xLjQtMC4yLTEuNyAxbDAuMSAxMi4xYzAuMiAxLjIgMC42IDEuNCAxLjcgMWwxMC40LTZjMC45LTAuNyAwLjktMS4zIDAtMnoiLz48L2c+PC9zdmc+Cg=="
      alt="overlay"
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "25px",
        height: "25px",
        opacity: 0.7,
      }}
    />
  );
}

function StateOverlay({ icon, text }: { icon: React.ReactNode; text: string }) {
  return (
    <Box
      style={{
        alignItems: "center",
        color: "white",
        gap: "15px",
        display: "flex",
        fontSize: "14px",
        fontWeight: 500,
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      {icon}
      <p>{text}</p>
    </Box>
  );
}

type VideoCardProps = {
  video: Video | { token: string };
  index?: number;
  maxIndex?: number;
  isNumbered?: boolean;
  isSelectable?: boolean;
  isChecked?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onToggle?: (selected: boolean) => void;
};

export function VideoCard({
  video: videoProp,
  isChecked,
  isSelectable,
  onClick,
  onToggle,
  isNumbered,
  index,
}: VideoCardProps) {
  const classes = useStyles();
  const { video } = useVideo(videoProp.token);

  if (!video) return <></>;
  const {
    created_at: date,
    default_stream: defStream,
    original_stream: origStream,
    ...videoData
  } = video;
  const imageUrl =
    video.local?.image_url ||
    (defStream && defStream.image_url) ||
    (origStream && origStream.image_url);
  const duration =
    video.local?.duration ||
    video.media_info?.duration ||
    video.default_stream?.media_info?.duration;
  const formattedDate = dayjs(date).format("MM-DD-YY");
  const formattedDuration = dayjs
    .duration(duration, "seconds")
    .format((() => "HH:mm:ss")());

  return (
    <DraggableItem id={video.token} key={video.token} direction="X">
      <Box sx={{ width: "150px", cursor: "pointer" }} onClick={onClick}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          {isNumbered && (
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "right",
                paddingRight: "5px",
              }}
            >
              {index + 1}
            </Typography>
          )}
          {isSelectable && (
            <KrgCheckbox
              className={classes.checkbox}
              theme={KrgCheckbox.THEME_ENUM.v2}
              isChecked={isChecked}
              onToggle={onToggle}
            />
          )}
        </Box>
        <div
          style={{
            position: "relative",
            marginBottom: "5px",
            aspectRatio: "16/9",
            background:
              video.state === "ready" ||
              (video.state === "processing" && video.local)
                ? "none"
                : "black",
          }}
        >
          {["failed"].includes(video.state) && (
            <StateOverlay
              icon={<RemoveCircle style={{ fontSize: "24px" }} color="error" />}
              text="Failed"
            />
          )}
          {(video.state === "ready" ||
            (video.state === "processing" && video.local)) && (
            <ResilientImage
              alt="video thumbnail"
              width="100%"
              style={{
                aspectRatio: "16/9",
                background: "black",
                objectFit: "contain",
                opacity: video.state === "uploading" ? 0.4 : 1,
              }}
              src={imageUrl}
              fallbacks={[videoData.hls_stream.image_url, origStream.image_url]}
            />
          )}
          {["empty", "uploaded", "processing"].includes(video.state) && (
            <StateOverlay
              icon={<Error color="warning" style={{ fontSize: "24px" }} />}
              text="Pending"
            />
          )}
          {(!["uploading", "empty", "failed"].includes(video.state) ||
            video.state === "processing") &&
            video.local && <PlayButton />}
          {video.state === "uploading" && (
            <LinearProgress
              variant="determinate"
              className={classes.progressbar}
              value={video.percentage_uploaded}
            />
          )}
        </div>
        <p
          style={{
            fontWeight: 500,
            fontSize: "12px",
            paddingLeft: "7px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflowX: "clip",
          }}
        >
          {getDisplayTitle(video)}
        </p>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "7px",
            paddingTop: "5px",
          }}
        >
          <Box display="flex" alignItems="center">
            <AccessTime sx={{ fontSize: "10px" }} />
            <Typography sx={{ fontSize: "10px" }}>
              {formattedDuration}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <DateRange sx={{ fontSize: "10px" }} />
            <Typography sx={{ fontSize: "10px" }}>{formattedDate}</Typography>
          </Box>
        </Box>
      </Box>
    </DraggableItem>
  );
}
