import React from "react";
import { Typography } from "@mui/material";
import KrgModal from "@kargo/shared-components.krg-modal";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import "./styles.scss";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";

type Props = {
  id?: string;
  elementClassName?: string;
  onSubmit: (v: string, isChecked?: boolean) => any;
  showCheckbox?: boolean;
  checkboxLabel?: string;
} & Pick<
  React.ComponentProps<typeof KrgModal>,
  "isOpen" | "onClose" | "title" | "submitButtonText" | "cancelButtonText"
> &
  Pick<React.ComponentProps<typeof KrgTextInput>, "text" | "label">;
export default function InputModal({
  id,
  elementClassName,
  isOpen,
  onClose,
  onSubmit,
  label,
  text,
  title,
  submitButtonText,
  cancelButtonText,
  showCheckbox = false,
  checkboxLabel,
}: Props) {
  const [value, setValue] = React.useState<string>();
  const [isChecked, setIsChecked] = React.useState<boolean>(false);

  const handleSubmit = () => {
    if (showCheckbox) {
      onSubmit(value || text, isChecked);
      setIsChecked(false);
    } else {
      onSubmit(value || text);
    }
  };
  return (
    <KrgModal
      className={`modal${elementClassName ? ` ${elementClassName}` : ""}`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmitClick={handleSubmit}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.submit}
      inputContainer={
        <>
          <KrgTextInput
            text={text}
            theme={KrgTextInput.THEME_ENUM.v2}
            onDebounceEnd={setValue}
            debounceTime={0}
            isFullWidth
            label={label}
          />
          {showCheckbox && (
            <KrgCheckbox
              theme={KrgCheckbox.THEME_ENUM.v2}
              isChecked={isChecked}
              onToggle={() => setIsChecked(!isChecked)}
              label={checkboxLabel}
            />
          )}
          {id && (
            <Typography className="id" color="#91A0AD" fontSize="10px">
              ID: {id}
            </Typography>
          )}
        </>
      }
      title={title}
      submitButtonText={submitButtonText || "Save"}
      cancelButtonText={cancelButtonText || "Cancel"}
    />
  );
}
