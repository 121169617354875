import React from "react";
import { Alert, Box } from "@mui/material";

import { useFormContext } from "../../../context/form/form.provider";
import FormFieldRenderer from "../../../components/FormContextComponents/FormFieldRenderer";

/**
 * SinglePlaylistEditor
 * @constructor
 */
const SinglePlaylistEditor = () => {
  const [{ formInputFields }] = useFormContext();

  return (
    <Box id="media-editor-form-container">
      {Object.keys(formInputFields).map((key: string) => {
        const {
          [key]: { ...field },
        } = formInputFields;
        if (!field.name || key !== field.name) {
          return (
            <Box key={key}>
              <Alert severity="warning">
                {`Field: "${key}" don't match with name key: "${field.name}"`}
              </Alert>
            </Box>
          );
        }
        return <FormFieldRenderer key={key} name={key} type={field.type} />;
      })}
    </Box>
  );
};

export default SinglePlaylistEditor;
