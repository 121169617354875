import React from "react";
import { FormControl, FormGroup, InputLabel } from "@mui/material";
import KrgSelect, {
  ItemI,
  MULTIPLE_TYPE_ENUM,
} from "@kargo/shared-components.krg-select";

import InfoPopover from "../InfoPopover";
import { FormInputFields } from "../../@types/form.types";
import { handleOnChange } from "../../context/form/form.actions";
import { useFormContext } from "../../context/form/form.provider";
import FieldHinterText from "./FieldHinterText";
import WrongFieldComponent from "./WrongFieldComponent";

const SelectFieldComponent = ({ fieldKey }: { fieldKey: string }) => {
  const [{ errors, formInputFields }, dispatch] = useFormContext();
  const selectField = formInputFields[fieldKey] as FormInputFields;
  const fieldErrors: string[] = errors[fieldKey];

  // options should contain at least one item which value is not empty
  const optionsCondition =
    Array.isArray(selectField?.options) &&
    (selectField.options as ItemI[])?.filter((v) => !!v).length > 0;
  const valueTypeCondition =
    selectField.isMultiple && !Array.isArray(selectField.value);

  if (!selectField || !optionsCondition || valueTypeCondition)
    return (
      <WrongFieldComponent
        fieldKey={fieldKey}
        message={`Wrong field name: "${fieldKey}" was provided as select field or options are not exists!`}
      />
    );

  const defaultSelectValue = selectField.value || "";
  if (
    defaultSelectValue === "" &&
    selectField.defaultValue &&
    (selectField.options as ItemI[])?.filter((v) => v.value === "").length === 0
  ) {
    (selectField.options as ItemI[]).unshift(selectField.defaultValue as ItemI);
  }

  return (
    <FormGroup
      className={`form-group-container ${fieldErrors ? "error-field-group" : ""}`}
    >
      {(selectField.label || selectField.popoverText) && (
        <InputLabel>
          {selectField.label && selectField.label}
          {selectField.popoverText && (
            <InfoPopover text={selectField.popoverText} />
          )}
        </InputLabel>
      )}
      <FormControl error={!!fieldErrors}>
        <KrgSelect
          value={selectField.value as string[]}
          items={selectField.options as ItemI[]}
          multipleType={MULTIPLE_TYPE_ENUM.checkmark}
          onChange={(value: string) =>
            handleOnChange(dispatch, selectField, value)
          }
          isMultiple={!!selectField.isMultiple}
          renderValue
          isFullWidth
        />
        {selectField.hinterText && (
          <FieldHinterText text={selectField.hinterText} errors={fieldErrors} />
        )}
      </FormControl>
    </FormGroup>
  );
};

export default SelectFieldComponent;
