import React from "react";
import { FormHelperText } from "@mui/material";

const FieldHinterText = ({
  text,
  errors,
}: {
  text: string;
  errors: string[];
}) => (
  <>
    {text && typeof errors === "undefined" && (
      <FormHelperText>{text}</FormHelperText>
    )}
    {errors?.length > 0 &&
      errors.map((err) => (
        <FormHelperText key={err} error color="error">
          {err}
        </FormHelperText>
      ))}
  </>
);

export default FieldHinterText;
