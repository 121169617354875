import React from "react";
import { FormProvider } from "../../../context/form/form.provider";
import { FormInitInputs } from "../../../@types/form.types";
import { GeneratePlaylistByType } from "./contentForm";

type PlaylistGenerateModalProps = {
  title: "tags" | "categories";
  isOpen?: boolean;
  onClose: () => void;
};
export default function PlaylistGenerateModal({
  title,
  isOpen,
  onClose,
}: PlaylistGenerateModalProps) {
  // const { categories } = useCategories(query);
  const formInitialState: FormInitInputs = {
    title: {
      name: "title",
      label: "Playlist Title",
      type: "text",
      required: true,
      rules: { length: { min: 5 } },
      value: title,
      popoverText: "Title of the playlist",
      hinterText: "*required",
    },
    sort: {
      name: "sort",
      label: "Video Sort",
      type: "select",
      required: true,
      value: "",
      options: [
        {
          value: "newest",
          text: "Date added (newest)",
        },
        {
          value: "oldest",
          text: "Date added (oldest)",
        },
        {
          value: "shuffle",
          text: "Shuffle",
        },
      ],
      popoverText: "Please select a sort type",
    },
    tags: {
      name: "tags",
      label: "Tags",
      type: "chip",
      value: [],
      popoverText: "Commas or semicolon will separate the tags",
    },
    excludedTags: {
      name: "excludedTags",
      label: "Excluded Tags",
      type: "chip",
      value: [],
      popoverText: "Commas or semicolon will separate the tags",
    },
    categories: {
      name: "categories",
      label: "Categories",
      type: "chip",
      value: [],
      popoverText: "Commas or semicolon will separate the Categories",
    },
  };
  return (
    <FormProvider formInputFields={formInitialState}>
      <GeneratePlaylistByType isOpen={isOpen} onClose={onClose} type={title} />
    </FormProvider>
  );
}
