import React from "react";
import {
  Box,
  FormControl,
  FormGroup,
  FormHelperText,
  InputLabel,
} from "@mui/material";

import KrgTextArea from "@kargo/shared-components.krg-text-area";

import { Video } from "../../../api/types";
import InfoPopover from "../../../components/InfoPopover";
import { handleOnChange } from "../../../context/form/form.actions";
import { useFormContext } from "../../../context/form/form.provider";
import UploadVTTInput from "../../../components/UploadVTTInput";
import CopyToClipboard from "../../../components/CopyToClipboard";
import TagFieldComponent from "../../../components/FormContextComponents/TagFieldComponent";
import SelectFieldComponent from "../../../components/FormContextComponents/SelectFieldComponent";
import TextInputFieldComponent from "../../../components/FormContextComponents/TextInputFieldComponent";

export const HinterText = ({
  text,
  errors,
}: {
  text: string;
  errors: string[];
}) => (
  <>
    {text && typeof errors === "undefined" && (
      <FormHelperText>{text}</FormHelperText>
    )}
    {errors?.length > 0 &&
      errors.map((err) => (
        <FormHelperText key={err} error color="error">
          {err}
        </FormHelperText>
      ))}
  </>
);

/**
 * SingleVideoEditor
 * @param useFormSate
 * @constructor
 */
const SingleMediaEditor = ({ video }: { video: Video }) => {
  const [{ formInputFields, errors }, dispatch] = useFormContext();

  const { title, description, tags, category } = formInputFields;

  return (
    <Box id="media-editor-form-container">
      {/* title */}
      {title && <TextInputFieldComponent fieldKey={title.name} />}

      {/* description */}
      {description && (
        <FormGroup
          className={`form-group-container ${errors?.description && "error-field-group"}`}
        >
          <InputLabel>
            {description.label}
            {description.popoverText && (
              <InfoPopover text={description.popoverText} />
            )}
          </InputLabel>
          <FormControl error={!!errors?.description}>
            <KrgTextArea
              name={description.name || "description"}
              rows={6}
              placeholder="Add a description"
              text={description.value?.toString() || ""}
              hasError={!!errors.description}
              onTextChange={(value) =>
                handleOnChange(dispatch, description, value)
              }
            />
            <HinterText
              text={description.hinterText}
              errors={errors?.description}
            />
          </FormControl>
        </FormGroup>
      )}

      {/* category */}
      {category && <SelectFieldComponent fieldKey={category.name} />}

      {/* tags */}
      {tags && <TagFieldComponent fieldKey={tags.name} />}

      {/* media URL */}
      {video.default_stream?.url && (
        <CopyToClipboard data={video.default_stream?.url} title="Media Link" />
      )}

      {/* VTT File Attachment */}
      {video.token && <UploadVTTInput token={video.token} />}
    </Box>
  );
};

export default SingleMediaEditor;
