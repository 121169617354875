import React from "react";
import AutoComplete from "../AutoComplete/AutoComplete";
import { AutocompleteProps } from "../../@types/media.types";
import { playlistTypes } from "../../constants/constants";

type Props = {
  asAdmin?: boolean;
  isEnabled?: boolean;
  value?: AutocompleteProps[];
  onChange?: (playlist: AutocompleteProps[]) => void;
};

export default function PlaylistTypeSelect({
  isEnabled = true,
  value = [],
  onChange,
}: Props) {
  return (
    <AutoComplete
      id="playlist-type-select"
      isEnabled={isEnabled}
      label="Type"
      placeholder="Add"
      value={value}
      onChange={onChange}
      options={playlistTypes}
    />
  );
}
