import React, { ReactNode } from "react";
import KrgModal, { SIZE_ENUM } from "@kargo/shared-components.krg-modal";
import KrgTextInput from "@kargo/shared-components.krg-text-input";
import "./styles.scss";

type Props = {
  elementClassName?: string;
  content: ReactNode;
  onSubmit?: (obj?: any) => any;
} & Pick<
  React.ComponentProps<typeof KrgModal>,
  | "isOpen"
  | "onClose"
  | "title"
  | "submitButtonText"
  | "cancelButtonText"
  | "isSubmitButtonEnabled"
  | "isCancelButtonEnabled"
> &
  Pick<React.ComponentProps<typeof KrgTextInput>, "text" | "label">;
export default function ContentModal({
  elementClassName,
  isOpen,
  onClose,
  onSubmit,
  content,
  title,
  submitButtonText,
  cancelButtonText,
  isSubmitButtonEnabled,
  isCancelButtonEnabled,
}: Props) {
  return (
    <KrgModal
      className={`content-modal modal${elementClassName ? ` ${elementClassName}` : ""}`}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={SIZE_ENUM.lg}
      onSubmitClick={(ev) => onSubmit(ev)}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.submit}
      inputContainer={content}
      title={title}
      isCancelButtonEnabled={isCancelButtonEnabled}
      isSubmitButtonEnabled={isSubmitButtonEnabled}
      submitButtonText={submitButtonText}
      cancelButtonText={cancelButtonText}
    />
  );
}
