import React, { useMemo } from "react";
import dayjs from "dayjs";
import { generatePath } from "react-router";
import { useHistory } from "react-router-dom";
import duration from "dayjs/plugin/duration";
import KrgDataTable from "@kargo/shared-components.krg-data-table";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { Playlist } from "../../@types/playlist.types";
import { PATHS } from "../../constants/paths";
import { DEFAULT_PAGINATION_LIMIT } from "../../constants/constants";
import { capitalizeStrings } from "../../helpers/capitilizeStrings";
import PlaylistsActions from "./PlaylistsActions";

dayjs.extend(duration);

const useStyles = makeStyles({
  table: {
    "& thead th": {
      background: "transparent",
      color: "black",
      fontSize: "11px",
      fontWeight: 600,
    },
    "& tbody": {
      borderTop: "2px solid black",
      borderBottom: "2px solid black",
      "& tr:hover": {
        background: "#EEF3F7",
      },
    },
  },
});

interface PlaylistsTableProps {
  playlists: Playlist[];
  selectedPlaylists?: string[];
  onPlaylistToggle?: (playlist: string, isSelected: boolean) => void;
  onToggleAll?: (checked: boolean) => void;
}

export default function PlaylistsTable({
  playlists,
  selectedPlaylists = [],
  onPlaylistToggle,
  onToggleAll,
}: PlaylistsTableProps) {
  const history = useHistory();
  const classes = useStyles();
  // TODO use below code for handling all the actions
  // const [modalVideoIndex, setModalVideoIndex] = useState(-1);
  // const [actionState, setActionState] = useState<PlaylistActionState>({
  //     isOpen: false,
  //     type: "delete",
  //     playlist: null,
  // });
  // const [snackbarState, setSnackbarState] = useState<SnackbarState>({
  //     type: TYPE_ENUM.success,
  //     isOpen: false,
  //     message: "",
  // });

  const isAllChecked = useMemo(() => {
    const selectedPlaylistsSet = new Set(selectedPlaylists);
    return (
      selectedPlaylistsSet.size > 0 &&
      playlists.every((playlist) => selectedPlaylistsSet.has(playlist.token))
    );
  }, [playlists, selectedPlaylists]);

  // TODO use below code for handling all the actions
  // const handleActionClick = (type: PlaylistActionType, playlist: Playlist) => {
  //     setActionState({ isOpen: true, type, playlist });
  // };

  const navigateToSinglePlaylist = (token: string) =>
    history.push(generatePath(PATHS.SINGLE_PLAYLIST_EDIT_PAGE, { token }));

  return (
    <>
      <Box sx={{ border: "1px solid #91A0AD" }}>
        {/* Data Table */}
        <KrgDataTable
          theme={KrgDataTable.THEME_ENUM.v2}
          className={classes.table}
          config={{
            pagination: {
              initialPageSize: DEFAULT_PAGINATION_LIMIT,
            },
            data: {
              columns: [
                {
                  type: KrgDataTable.CELL_TYPE_ENUM.checkbox,
                  headerName: "",
                  field: "isChecked",
                  renderHeader: () => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={isAllChecked}
                      onToggle={onToggleAll}
                    />
                  ),
                  renderCell: (row) => (
                    <KrgCheckbox
                      theme={KrgCheckbox.THEME_ENUM.v2}
                      isChecked={row.isChecked}
                      isEnabled={row.state}
                      onToggle={(isChecked) =>
                        onPlaylistToggle?.(row.token, isChecked)
                      }
                    />
                  ),
                },
                {
                  headerName: "Playlist Title",
                  type: KrgDataTable.CELL_TYPE_ENUM.subtitle,
                  field: "playlist_title",
                  onClick: (_, row) => navigateToSinglePlaylist(row.token),
                  width: "450px",
                },
                {
                  headerName: "No. of Videos",
                  field: "medias",
                  width: "130px",
                },
                {
                  headerName: "Type",
                  field: "type",
                  width: "120px",
                  renderCell: (row) => (
                    <>{capitalizeStrings(row.total_duration, "_")}</>
                  ),
                },
                {
                  headerName: "Total Duration",
                  field: "total_duration",
                  width: "120px",
                },
                {
                  headerName: "Date Updated",
                  field: "updated_at",
                  width: "120px",
                },
                {
                  headerName: "Actions",
                  field: "actions",
                  width: "120px",
                  renderCell: (row: Playlist) => (
                    <PlaylistsActions
                      playlist={row}
                      onEditActionClick={() =>
                        navigateToSinglePlaylist(row.token)
                      }
                    />
                  ),
                },
              ],
              rows: playlists.map((playlist) => ({
                ...playlist,
                playlist_title: { title: playlist.title },
                updated_at: dayjs(playlist.updated_at).format("MM/DD/YYYY"),
                medias: playlist?.medias?.length,
                isChecked: selectedPlaylists.includes(playlist.token),
              })),
            },
          }}
        />
      </Box>
    </>
  );
}
