import React from "react";
import KrgModal from "@kargo/shared-components.krg-modal";
import { makeStyles } from "@mui/styles";
import "./styles.scss";

const useStyles = makeStyles({
  paper: {
    "& .MuiPaper-root": {
      height: "192px !important", // important flag was necessary to override the styles in KrgModal shared component
      padding: "20px",
      margin: 0,
      width: "min(590px, 50%)",
    },
  },
});
// eslint-disable-next-line max-len
type Props = Pick<
  React.ComponentProps<typeof KrgModal>,
  | "isOpen"
  | "onClose"
  | "header"
  | "body"
  | "submitButtonText"
  | "cancelButtonText"
  | "onSubmitClick"
  | "inputContainer"
>;
export default function ConfirmModal({
  isOpen,
  onClose,
  onSubmitClick,
  header,
  body,
  submitButtonText,
  cancelButtonText,
  inputContainer,
}: Props) {
  const classes = useStyles();
  return (
    <KrgModal
      className={`confirm-modal ${classes.paper}`}
      isOpen={isOpen}
      onClose={onClose}
      onSubmitClick={onSubmitClick}
      theme={KrgModal.THEME_ENUM.v2}
      type={KrgModal.TYPE_ENUM.confirmation}
      header={header}
      body={body}
      submitButtonText={submitButtonText || "Save"}
      cancelButtonText={cancelButtonText || "Cancel"}
      inputContainer={inputContainer}
    />
  );
}
