import {
  UploadActionTypes,
  UploadContextState,
  UploadActionEnums,
} from "../../@types/upload.types";
import { MediaState } from "../../@types/media.types";
import { UploadResultStatesEnums } from "../../@types/api.types";
import { uploadProcessInitialState } from "../../constants/provider.states";

export default (
  state: UploadContextState,
  action: UploadActionTypes,
): UploadContextState => {
  switch (action.type) {
    case UploadActionEnums.UPLOAD_STARTED:
      return {
        ...state,
        uploadInProgress: true,
        uploadingFilesCount: action.payload.uploadingFilesCount,
      };

    case UploadActionEnums.VIDEO_CREATION_STARTED: {
      const { uploadingVideos } = state;
      const { title, index, file } = action.payload;
      uploadingVideos[index] = {
        title,
        progress: 0,
        completed: false,
        status: MediaState.EMPTY,
        file,
      };
      return { ...state, uploadingVideos };
    }

    case UploadActionEnums.VIDEO_SIGNED_URL_CREATED: {
      const { uploadingVideos } = state;
      const { token, index, videoURL, imageURL } = action.payload;
      uploadingVideos[index] = {
        ...uploadingVideos[index],
        token,
        isUploading: true,
        video: { signedURL: videoURL },
        image: { signedURL: imageURL },
      };
      return {
        ...state,
        uploadingVideos,
      };
    }

    case UploadActionEnums.VIDEO_UPLOAD_PROGRESS: {
      const { uploadingVideos } = state;
      const { index, progress } = action.payload;
      uploadingVideos[index] = {
        ...uploadingVideos[index],
        progress,
        status: MediaState.UPLOADING,
      };
      return { ...state, uploadingVideos };
    }

    case UploadActionEnums.VIDEO_UPLOAD_ENDED: {
      const { uploadingVideos } = state;
      const { index, statuses } = action.payload;
      uploadingVideos[index] = {
        ...uploadingVideos[index],
        isUploading: false,
        progress: 100,
        completed: true,
        status:
          statuses[0] === UploadResultStatesEnums.FULFILLED
            ? MediaState.PROCESSING
            : MediaState.FAILED,
        video: {
          state: statuses[0],
          // Will be usefully if we want to resend/resume after process aborted
          // we can apply to expire as well based on S3 policy as well
          ...(statuses[0] === UploadResultStatesEnums.FULFILLED && {
            signedURL: null,
          }),
        },
        image: {
          state: statuses[1],
          ...(statuses[1] === UploadResultStatesEnums.FULFILLED && {
            signedURL: null,
          }),
        },
      };
      return {
        ...state,
        uploadingVideos,
        uploadInProgress:
          uploadingVideos.filter((v) => !!v.isUploading).length > 0,
      };
    }

    case UploadActionEnums.UPLOAD_ABORTED:
      return {
        ...state,
        uploadingFilesCount: 0,
        uploadInProgress: false,
        uploadingVideos: state.uploadingVideos.map((v) => ({
          ...v,
          isUploading: false,
          progress: 0,
          status: MediaState.ABORTED,
          completed: true,
        })),
      };

    case UploadActionEnums.CLOSE_UPLOAD_INDICATOR_CONTAINER:
      return { ...state, ...uploadProcessInitialState };

    default:
      return state;
  }
};
