import { MediaState } from "./media.types";
import { UploadResultState } from "./api.types";

export enum UploadActionEnums {
  // VIDEO UPLOAD
  UPLOAD_STARTED = "UPLOAD_STARTED",
  CLOSE_UPLOAD_INDICATOR_CONTAINER = "CLOSE_UPLOAD_INDICATOR_CONTAINER",
  VIDEO_CREATION_STARTED = "VIDEO_CREATION_STARTED",
  VIDEO_SIGNED_URL_CREATED = "VIDEO_SIGNED_URL_CREATED",
  VIDEO_UPLOAD_PROGRESS = "VIDEO_UPLOAD_PROGRESS",
  UPLOAD_ABORTED = "UPLOAD_ABORTED",
  VIDEO_UPLOAD_ENDED = "VIDEO_UPLOAD_ENDED",
}

/* ////// UPLOAD CONTEXT TYPES ////// */
export type UploadingItemType = {
  title: string;
  progress: number;
  completed: boolean;
  status: MediaState;
  file: File;
  token?: string;
  isUploading?: boolean;
  video?: {
    signedURL: string;
    state?: UploadResultState;
    expireAt?: number;
  };
  image?: {
    signedURL: string;
    state?: UploadResultState;
    expireAt?: number;
  };
};

export interface UploadContextState {
  uploadInProgress: boolean;
  abortController: AbortController;
  uploadingFilesCount: number;
  uploadingVideos: UploadingItemType[];
}

export type UploadActionTypes =
  | {
      // Actions without payload
      type:
        | UploadActionEnums.UPLOAD_ABORTED
        | UploadActionEnums.CLOSE_UPLOAD_INDICATOR_CONTAINER;
      payload?: undefined;
    }
  | {
      type: UploadActionEnums.UPLOAD_STARTED;
      payload: { uploadingFilesCount: number };
    }
  | {
      type: UploadActionEnums.VIDEO_CREATION_STARTED;
      payload: { index: number; title: string; file: File };
    }
  | {
      type: UploadActionEnums.VIDEO_SIGNED_URL_CREATED;
      payload: {
        index: number;
        token: string;
        videoURL: string;
        imageURL: string;
      };
    }
  | {
      type: UploadActionEnums.VIDEO_UPLOAD_PROGRESS;
      payload: { index: number; progress: number };
    }
  | {
      type: UploadActionEnums.VIDEO_UPLOAD_ENDED;
      payload: {
        index: number;
        statuses: {
          video: UploadResultState;
          image: UploadResultState;
        };
      };
    };

export type UploadDispatcher = ({ type, payload }: UploadActionTypes) => void;
