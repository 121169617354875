/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { debounce } from "lodash";
import SearchInput from "../../components/SearchInput";
import useMRSSFeeds from "../../hooks/useMRSSFeeds";
import FeedAccordion from "./FeedAccordion";
import CategoriesSelect from "../../components/Select/CategoriesSelect";
import DurationSelect from "../../components/DurationSelect/DurationSelect";
import { DatePicker, DateRange } from "../../components/DatePicker/DatePicker";
import LoadingCircle from "../../components/LoadingCircle";
import tracker from "../../helpers/tracker";

type Props = {
  asAdmin?: boolean;
  publisherSlug?: string;
  createOptions?: {
    isVisible: boolean;
    onClose: () => void;
    onSubmit: () => void;
  };
  selected: string[];
  onChangeSelected: (selected: string[]) => void;
};
export default function MRSSFeeds({
  asAdmin,
  publisherSlug,
  selected,
  onChangeSelected,
  createOptions: copt,
}: Props) {
  const [search, setSearch] = useState("");
  const { data: feeds, query: feedsQuery } = useMRSSFeeds({
    ...(publisherSlug && { slug: publisherSlug }),
  });
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedDurations, setSelectedDurations] = useState<number[]>([]);
  const [dateRange, setDateRange] = useState<DateRange | undefined>();
  const query = {
    ...(search && { text: search }),
    ...(selectedCategories.length > 0 && { category: selectedCategories }),
    ...(selectedDurations.length > 0 && { duration: selectedDurations }),
    ...(dateRange &&
      dateRange.to &&
      dateRange.from && {
        date_range: dateRange,
      }) /* We can remove the restriction if needed */,
    ...(publisherSlug && { slug: publisherSlug }),
  };
  const debouncedTrack = React.useMemo(
    () =>
      debounce((event, data) => {
        tracker.track(event, data);
      }, 3000),
    [],
  );

  useEffect(() => {
    if (
      search ||
      selectedCategories?.length > 0 ||
      selectedDurations?.length > 0 ||
      dateRange
    ) {
      debouncedTrack("Search", {
        resource: "video",
        text: search,
        categories: selectedCategories,
        durations: selectedDurations,
        date: dateRange,
      });
    }
  }, [search, selectedCategories, selectedDurations, dateRange]);
  return (
    <Box>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          md={12}
          gap={3}
          justifyContent="flex-start"
        >
          <SearchInput placeholder="Search" onDebounceEnd={setSearch} />
          <Grid item xs={12} sm={3}>
            <CategoriesSelect
              asAdmin={asAdmin}
              // TODO: need to fix the type of selectedCategories, as it's not a string[]
              value={selectedCategories}
              onChange={(cat: string) => {
                setSelectedCategories([...selectedCategories, cat]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DurationSelect
              asAdmin={asAdmin}
              value={selectedDurations}
              onChange={(duration: number) => {
                setSelectedDurations([...selectedDurations, duration]);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <DatePicker
              onDateRangeChange={(selectedDate) => {
                setDateRange(selectedDate);
              }}
              value={dateRange}
            />
          </Grid>
        </Grid>
      </Grid>
      {feedsQuery.isFetched ? (
        <>
          {feeds?.length > 0 &&
            feeds.map((f) => (
              <FeedAccordion
                key={f.token}
                feed={f}
                query={query}
                selected={selected}
                onChangeSelected={onChangeSelected}
              />
            ))}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "calc(100vh - 259px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LoadingCircle />
        </div>
      )}
    </Box>
  );
}
