/* eslint-disable @typescript-eslint/naming-convention */
import mixpanel from "mixpanel-browser";

const tracker = (() => {
  let isInitialized = false;

  const shouldInit = () => {
    if (isInitialized) return false;
    return (
      window.location.host === "cms-stage.kargovideo.com" ||
      window.location.host === "video.fabrik.com"
    );
  };

  const init = (config = {}) => {
    if (!shouldInit()) return;
    let token;
    if (window.location.host === "cms-stage.kargovideo.com")
      token = process.env.MIXPANEL_STAGE_TOKEN;
    if (window.location.host === "video.fabrik.com")
      token = process.env.MIXPANEL_PROD_TOKEN;
    if (!token) throw Error("Missing token");
    mixpanel.init(token, config);
    isInitialized = true;
  };

  const add_group = (key: string, id: any) => {
    if (!isInitialized) return;
    mixpanel.add_group(key, id);
  };

  const track = (event, properties) => {
    if (!isInitialized) return;
    mixpanel.track(event, properties);
  };

  const track_button_click = (id: string) => {
    if (!isInitialized) return;
    mixpanel.track("Button Clicked", {
      button: id,
    });
  };

  const track_pageview = (page?) => {
    if (!isInitialized) return;
    mixpanel.track_pageview(page);
  };

  const identify = (id) => {
    if (!isInitialized) return;
    mixpanel.identify(id);
  };

  const reset = () => {
    if (!isInitialized) return;
    mixpanel.reset();
  };

  const people = {
    set: (data) => {
      if (!isInitialized) return;
      mixpanel.people.set(data);
    },
  };

  return {
    add_group,
    init,
    track,
    track_pageview,
    track_button_click,
    identify,
    reset,
    people,
    // Export other methods as needed
  };
})();

export default tracker;
