import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { FileUpload, Edit, PlaylistAdd } from "@mui/icons-material";
import Playlists from "./playlists";
import SectionNavBar from "../../components/SectionNavBar";
import useQueryParam from "../../hooks/useQueryParam";
import AddToPlaylist from "./AddToPlaylist";
import UploadVideoFilesComponent from "../../components/UploadVideoFilesComponent";
import { usePublisherContext } from "../../hooks/usePublisherProvider";
import MRSSFeeds from "./MRSSFeeds";
import VideoLibrary from "./VideoLibrary";
import AnchorMenu from "../../components/Menu/AnchorMenu";
import BulkEditMediaModal from "../../components/Modals/BulkEditMediaModal";
import { Video } from "../../api/types";
import { Query } from "../../hooks/useVideos";
import { BulkEditType } from "../../@types/media.types";
import { useUploadStateContext } from "../../context/upload/upload.provider";
import { isAddToPlaylistEnabled } from "../../constants/constants";

export default function MediaGallery(
  {
    asAdmin,
    publisherGroup: groupName,
  }: { asAdmin?: boolean; publisherGroup: string } = {
    asAdmin: false,
    publisherGroup: "",
  },
) {
  const { publisherSlug, publisherGroup, publisherUsername } =
    usePublisherContext(asAdmin);
  const [isCreateFormVisible, setIsCreateFormVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [anchorElMenu, setAnchorElMenu] = useState<HTMLButtonElement | null>(
    null,
  );
  const tab = useQueryParam("tab", (t) => parseInt(t, 10) || 0);
  const [selectedTab, setSelectedTab] = useState(tab);
  const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
  const [bulkEditType, setBulkEditType] = useState<BulkEditType>(
    BulkEditType.TAGS,
  );
  const [selectedPlaylists, setSelectedPlaylists] = useState([]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [isBulkDeleteOpen, setIsBulkDeleteOpen] = useState<boolean>(false);
  const [generatePlaylistType, setGeneratePlaylistType] = useState<
    "tags" | "categories" | undefined
  >();
  const [isEditMenuOpen, setIsEditMenuOpen] = useState<boolean>(false);
  const [isPlaylistMenuOpen, setIsPlaylistMenuOpen] = useState<boolean>(false);
  const [isBulkEditOpen, setIsBulkEditOpen] = useState<boolean>(false);
  const [videoQuery, setVideoQuery] = useState<Query>({});
  const { uploadInProgress } = useUploadStateContext();
  const createOptions = {
    isVisible: isCreateFormVisible,
    onClose: () => setIsCreateFormVisible(false),
    onSubmit: () => {
      setIsCreateFormVisible(false);
    },
  };
  const tabs = [
    { label: "Video Library" },
    { label: "Playlists" },
    { label: "MRSS Feed" },
  ];
  const history = useHistory();
  const buttonsConfig = {
    0: [
      {
        id: "upload-videos",
        key: "upload-videos",
        text: uploadInProgress ? "Uploading..." : "Upload Videos",
        startIcon: <FileUpload />,
        isEnabled: !uploadInProgress,
        onClick: () => setShowUploadPopup(true),
      },
      {
        id: "edit-videos",
        key: "edit-videos",
        text: "Edit",
        startIcon: <Edit />,
        isEnabled: selectedVideos.length > 0,
        onClick: (e) => {
          setAnchorElMenu(e.currentTarget);
          setIsEditMenuOpen(true);
        },
      },
      {
        id: "add-to-playlist",
        key: "add-to-playlist",
        text: "Add to Playlist",
        isEnabled: isAddToPlaylistEnabled && selectedVideos.length > 0,
        onClick: (e) => {
          setAnchorEl(e.currentTarget);
        },
      },
    ],
    1: [
      {
        id: "edit-playlists",
        key: "edit-playlists",
        text: "Edit",
        startIcon: <Edit />,
        isEnabled: false,
        onClick: (e) => {
          setAnchorElMenu(e.currentTarget);
        },
      },
      {
        id: "generate-playlist",
        key: "generate-playlist",
        text: "Generate Playlist",
        startIcon: <PlaylistAdd />,
        onClick: (e) => {
          setAnchorElMenu(e.currentTarget);
          setIsPlaylistMenuOpen(true);
        },
        isEnabled: !isCreateFormVisible,
      },
    ],
    2: [
      {
        id: "edit-mrss",
        key: "edit-mrss",
        text: "Edit",
        startIcon: <Edit />,
        isEnabled: false,
        onClick: (e) => {
          setAnchorElMenu(e.currentTarget);
        },
      },
    ],
  };

  const editMenuItems = [
    {
      label: "Categories",
      onClick: () => {
        setBulkEditType(BulkEditType.CATEGORY);
        setIsBulkEditOpen(true);
      },
    },
    {
      label: "Tags",
      onClick: () => {
        setBulkEditType(BulkEditType.TAGS);
        setIsBulkEditOpen(true);
      },
    },
    {
      label: "Delete Selected",
      onClick: () => setIsBulkDeleteOpen(true),
    },
  ];

  const generatePlaylistMenu = [
    {
      label: "Generate by categories",
      // TODO: Add function for edit by categories
      onClick: () => setGeneratePlaylistType("categories"),
    },
    {
      label: "Generate by tags",
      // TODO: Add function for edit by tags
      onClick: () => setGeneratePlaylistType("tags"),
    },
  ];

  const renderTabContent = () => {
    switch (tab) {
      case 0:
        return (
          <VideoLibrary
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            createOptions={createOptions}
            selectedVideos={selectedVideos}
            onChangeSelected={setSelectedVideos}
            isBulkDeleteOpen={isBulkDeleteOpen}
            setIsBulkDeleteOpen={setIsBulkDeleteOpen}
            query={videoQuery}
            setQuery={setVideoQuery}
          />
        );
      case 1:
        return (
          <Playlists
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            publisherGroup={groupName || publisherGroup}
            publisherUsername={publisherUsername}
            createOptions={createOptions}
            selectedPlaylists={selectedPlaylists}
            onChangeSelected={setSelectedPlaylists}
            generateFormType={generatePlaylistType}
            closeGenerateForm={() => setGeneratePlaylistType(undefined)}
          />
        );
      case 2:
        return (
          <MRSSFeeds
            asAdmin={asAdmin}
            publisherSlug={publisherSlug}
            createOptions={createOptions}
            selected={selectedVideos}
            onChangeSelected={setSelectedVideos}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isCreateFormVisible) setIsCreateFormVisible(false);
    history.push(`${window.location.pathname}?tab=${selectedTab}`);
  }, [selectedTab]);

  useEffect(() => {
    if (showUploadPopup) setShowUploadPopup(false);
  }, [showUploadPopup]);

  return (
    <Box padding="20px">
      <Box display="flex" flexDirection="column" justifyContent="center">
        <Box sx={{ fontSize: "24px", marginY: "12px" }}>Media Gallery</Box>
        {/* Edit popup modal */}
        <AnchorMenu
          anchorEl={anchorElMenu}
          open={isEditMenuOpen}
          onClose={() => setIsEditMenuOpen(false)}
          menuItems={editMenuItems}
        />
        {/* generate playlist popup modal */}
        <AnchorMenu
          anchorEl={anchorElMenu}
          open={isPlaylistMenuOpen}
          onClose={() => setIsPlaylistMenuOpen(false)}
          menuItems={generatePlaylistMenu}
        />

        {/* Edit popup modal */}
        <BulkEditMediaModal
          isOpen={isBulkEditOpen}
          onClose={() => setIsBulkEditOpen(false)}
          videos={selectedVideos}
          bulkEditType={bulkEditType}
          asAdmin={asAdmin}
          query={videoQuery}
        />

        {/* Upload files popup modal */}
        <UploadVideoFilesComponent
          showUploadPopup={showUploadPopup && !uploadInProgress}
          publisherGroup={groupName || publisherGroup}
        />
        {/* Add To Playlist popup modal */}
        <AddToPlaylist
          asAdmin={asAdmin}
          anchorEl={anchorEl}
          publisherSlug={publisherSlug}
          onClose={() => setAnchorEl(null)}
          onSubmit={() => setSelectedVideos([])}
          videos={selectedVideos.map((video) => video.token)}
        />
        {/* Tabs content with section navbar */}
        <SectionNavBar
          buttonsConfig={buttonsConfig[selectedTab]}
          tabsConfig={{
            onChange: (e: any, value?: any) => setSelectedTab(value),
            selectedTab,
            tabs,
          }}
        />
        {renderTabContent()}
      </Box>
    </Box>
  );
}
