import React from "react";
import { Alert, Box } from "@mui/material";

const WrongFieldComponent = ({
  fieldKey,
  message = `Wrong condition was applied for field "${fieldKey}"`,
}: {
  fieldKey: string;
  message?: string;
}) => (
  <Box>
    <Alert severity="warning">{message}</Alert>
  </Box>
);

export default WrongFieldComponent;
