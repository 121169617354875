import React from "react";
import { Collection } from "@aws-amplify/ui-react";
import { VideoCard } from "./VideoCard";
import { Video } from "../api/types";
import VideoModal from "./Modals/VideoModal";
import { DraggableList } from "./DraggingProvider";

type VideosCollectionProps = {
  videos: Array<Video | { token: string }>;
  onMove?: (prevIndex: number, newIndex: number) => any;
  onVideoClick?: (
    token: string,
    event: React.MouseEvent<HTMLDivElement>,
  ) => void;
  onVideoToggle?: (token: string, selected: boolean) => void;
  isNumbered?: boolean;
  isSelectable?: boolean;
  isDraggable?: boolean;
  selected?: string[];
};

export function VideosCollection({
  videos,
  onMove,
  onVideoClick,
  onVideoToggle,
  isNumbered,
  isSelectable,
  isDraggable,
  selected,
}: VideosCollectionProps) {
  const [modalVideoIndex, setModalVideoIndex] = React.useState(-1);
  const modalVideo = videos[modalVideoIndex];
  const totalVideos = videos.length;
  return (
    <>
      <VideoModal
        video={modalVideo?.token}
        isOpen={modalVideoIndex > -1}
        onClose={() => setModalVideoIndex(-1)}
        onNavigatePrev={() =>
          setModalVideoIndex((modalVideoIndex - 1 + totalVideos) % totalVideos)
        }
        onNavigateNext={() =>
          setModalVideoIndex((modalVideoIndex + 1) % totalVideos)
        }
      />
      <DraggableList
        draggableList={videos}
        itemIdKey="token"
        onDragEnd={(drop, drag) => onMove && onMove(drop, drag)}
        isDraggable={isDraggable}
      >
        <Collection
          items={videos}
          type="list"
          direction="row"
          wrap="wrap"
          gap="20px 30px"
        >
          {(video, index) => (
            <React.Fragment key={index}>
              <VideoCard
                index={index}
                maxIndex={videos.length - 1}
                video={video}
                isNumbered={isNumbered}
                isSelectable={isSelectable}
                isChecked={selected?.includes(video.token)}
                onClick={(e) => {
                  setModalVideoIndex(index);
                  if (onVideoClick) onVideoClick(video.token, e);
                }}
                onToggle={(value) => onVideoToggle(video.token, value)}
              />
            </React.Fragment>
          )}
        </Collection>
      </DraggableList>
    </>
  );
}
