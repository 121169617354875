import { KeyboardEvent, RefObject } from "react";
import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";
import {
  FormActionsEnum,
  FormInputFields,
  FormValue,
  FormDispatch,
  FormError,
  FormInitInputs,
} from "../../@types/form.types";

// NOTE: using hooks in non react components outside of Provider can call cause trouble,
// so why dispatch and function using hooks are passing as an argument
/**
 * Will check initial form inputs for error existence based on provided rule(-s)
 * @param dispatch
 * @param inp
 */
export const checkInitialFormValidation = (
  dispatch: FormDispatch,
  inp: FormInitInputs,
) =>
  Object.keys(inp).forEach(
    (key) =>
      inp[key]?.required &&
      dispatch({
        type: FormActionsEnum.VALIDATE_FORM_FIELD,
        payload: { field: inp[key], value: inp[key].value },
      }),
  );

/**
 * Will submit form if no errors found
 * @param dispatch
 * @param errors
 * @param data
 * @param action
 */
export const submitForm = (
  dispatch: FormDispatch,
  errors: FormError,
  data: { [key: string]: FormValue },
  action: (arg: { [key: string]: FormValue }) => void,
) => {
  if (Object.keys(errors).length === 0 && Object.keys(data).length > 0) {
    dispatch({ type: FormActionsEnum.SET_FORM_SUBMITTING });
    try {
      action.call(this, data);
    } catch (err) {
      dispatch({
        type: FormActionsEnum.SET_FORM_SUBMIT_ERROR,
        payload: "Error when tried to submit changes, please try later",
      });
    } finally {
      dispatch({ type: FormActionsEnum.SET_FORM_SUBMITTED });
    }
  } else {
    dispatch({
      type: FormActionsEnum.SET_FORM_SUBMIT_ERROR,
      payload: "Please recheck all fields and be sure all are filled correctly",
    });
  }
};

/**
 * Will handle form field change
 * @param dispatch
 * @param field
 * @param value
 */
export const handleOnChange = (
  dispatch: FormDispatch,
  field: FormInputFields,
  value?: FormValue,
) =>
  dispatch({
    type: FormActionsEnum.CHANGE_FORM_FIELD,
    payload: { field, value },
  });

/**
 * Add tag to the form field
 * @param dispatch
 * @param ev
 * @param field
 * @param ref
 */
export const handleAddTag = (
  dispatch: FormDispatch,
  ev: KeyboardEvent,
  field: FormInputFields,
  ref: RefObject<HTMLInputElement>,
): void => {
  // keyCode, charCode are deprecated
  const { code, key } = ev;
  if (code === "Enter" || (key === "Enter" && ref.current)) {
    if (!ref.current.value) return;
    const prevValues = (field.value as ChipData[]).map((v) => v.text);
    const values = ref.current.value
      .split(/[,|;]/g)
      .map((val) => val.trim())
      .filter((val) => val.length > 0 && !prevValues.includes(val));
    if (values.length > 0) {
      dispatch({
        type: FormActionsEnum.ADD_TAG,
        payload: {
          field,
          value: values,
        },
      });
    }
    // eslint-disable-next-line no-param-reassign
    ref.current.value = "";
  }
};

/**
 * Remove tag from the form field
 * @param dispatch
 * @param tag
 * @param field
 */
export const handleDeleteTag = (
  dispatch: FormDispatch,
  tag: ChipData,
  field: FormInputFields,
) =>
  dispatch({
    type: FormActionsEnum.REMOVE_TAG,
    payload: {
      field,
      value: tag,
    },
  });
