export const USER_API =
  process.env.API_URL ||
  `https://user${process.env.NODE_ENV === "staging" ? "-stage.kargovideo" : ".video.fabrik"}.com/api/v1`;
export const PUBLIC_API =
  process.env.PUBLIC_API_URL ||
  `https://public${process.env.NODE_ENV === "staging" ? "-stage" : ""}.kargovideo.com/api/v1`;
export const LOOKER_HOST = "looker.kargo.com";
export const LOOKER_DASHBOARD_ID = 2897;
export const STAGING = process.env.NODE_ENV === "staging";

export default {};
