import { ItemI } from "@kargo/shared-components.krg-select";
import { PATHS } from "./paths";

export const DEFAULT_PAGINATION_LIMIT = 12;
export const MEDIAS_FETCH_LIMIT = DEFAULT_PAGINATION_LIMIT;
export const MAX_BULK_EDIT_LIMIT = 100;
export const MAX_ALLOWED_VIDEO_UPLOADS = 10;
export const UPDATE_PROGRESS_ON_PERCENTAGE = 25;
export const WARNING_TEXT_COLOR = "#FBC02D";
export const isAddToPlaylistEnabled = false;

export const MIN_CHARACTERS_FOR_SEARCH = 3;
export const DEBOUNCE_TIME_FOR_SEARCH_IN_MICROSECONDS = 500;
export const PLAYLIST_FETCH_LIMIT = 100;
export const NavItems = {
  PUBLISHER_HUB: "Publisher Hub",
  MEDIA_GALLERY: "Media Gallery",
  DASHBOARDS: "Dashboards",
  REPORTS: "Reports",
};

export const sidebarNavItems = [
  { path: PATHS.DASHBOARDS, text: NavItems.DASHBOARDS },
  { path: PATHS.PUBLISHER_HUB, text: NavItems.PUBLISHER_HUB },
  { path: PATHS.MEDIA_GALLERY, text: NavItems.MEDIA_GALLERY },
  { path: PATHS.REPORTING, text: NavItems.REPORTS },
];

export const ALLOWED_VTT_FILES_MIME_TYPES = [
  `text/vtt`,
  `text/srt`,
  `text/plain`,
];

export const PLAYLIST_MIN_TITLE_LENGTH = 5;
export const PLAYLIST_MAX_TITLE_LENGTH = null;
export const playlistTypes = [
  {
    id: 1,
    name: "Category",
  },
  {
    id: 2,
    name: "Tags",
  },
];

export const PLAYLIST_SORT_TYPES: ItemI[] = [
  {
    value: `newest`,
    text: `Date added (newest first)`,
  },
  {
    value: `oldest`,
    text: `Date added (oldest first)`,
  },
  {
    value: `shuffle`,
    text: `Shuffle`,
  },
];
