import { useMutation, useQuery, useQueryClient } from "react-query";
import useAPI from "../api/useAPI";
import { Domain, Config, BulkDomainsProperties } from "../api/types";

type Query = {
  limit?: number;
  skip?: number;
  slug?: string;
  sort?: any;
  token?: string;
};

type UseDomainsArgs = { enabled?: boolean; query?: Query };

export function useDomains(
  { enabled, query }: UseDomainsArgs = { enabled: false },
) {
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const fetchDomains = async (q?: Query) => {
    let domains = await api.getDomains(q);
    domains = domains.map((domain) => {
      domain.configs.sort(
        (c1, c2) =>
          new Date(c2.created_at).getTime() - new Date(c1.created_at).getTime(),
      );
      return domain;
    });
    return domains.sort(
      (d1, d2) =>
        new Date(d1.created_at).getTime() - new Date(d2.created_at).getTime(),
    );
  };
  // eslint-disable-next-line max-len
  const domainsQuery = useQuery(
    ["domain-configs", query || {}],
    () => fetchDomains(query),
    {
      staleTime: Infinity,
      keepPreviousData: true,
      enabled: enabled && didLoad,
    },
  );
  const createMutation = useMutation({
    mutationFn: ({
      domain,
      username,
      createAdUnits,
    }: {
      domain: string;
      username: string;
      createAdUnits: boolean;
    }) => api.createDomain({ domain, username, createAdUnits }),
    onSuccess: (response) => {
      const previousData = queryClient.getQueryData<Domain[]>([
        "domain-configs",
        query,
      ]);
      const domainData = {
        configs: [],
        created_at: new Date().toISOString(),
        created_by: "TODO",
        updated_at: new Date().toISOString(),
        updated_by: "TODO",
        ...response,
      };
      queryClient.setQueryData(
        ["domain-configs", query],
        previousData?.concat(domainData),
      );
      queryClient.setQueryData(
        ["domain", { token: response.token }],
        domainData,
      );
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const deleteMutation = useMutation({
    mutationFn: (token: string) => api.deleteDomain(token),
    onSuccess: (_, token) => {
      queryClient.setQueryData(["domain", { token }], undefined);
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const updateConfigMutation = useMutation({
    mutationFn: ({ config, domain }: { config: Config; domain: string }) =>
      api.domain(domain).setConfig(config.token, config),
    onSuccess: (response: { domain: Domain }) => {
      queryClient.setQueryData(
        ["domain", { token: response.domain.token }],
        response.domain,
      );
      response.domain.configs.forEach((c) =>
        queryClient.setQueryData(["domain-config", { token: c.token }], c),
      );
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const updateDomain = useMutation({
    mutationFn: ({ data, domain }: { data: Partial<Domain>; domain: string }) =>
      api.domain(domain).update({ ...data }),
    onSuccess: (response: { domain: Domain }) => {
      queryClient.setQueryData(
        ["domain", { token: response.domain.token }],
        response.domain,
      );
      response.domain.configs.forEach((c) =>
        queryClient.setQueryData(["domain-config", { token: c.token }], c),
      );
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });

  const saveTargetingUrls = useMutation({
    mutationFn: ({
      targeting_urls,
      domain,
      token,
      configToken,
    }: {
      targeting_urls: string[];
      domain: string;
      token: string;
      configToken: string;
    }) => api.saveTargetingUrls({ targeting_urls, domain }, token, configToken),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["targetingUrls", { targeting_urls: response.body[0] }],
        response.body[0],
      );
    },
  });

  const deleteTargetingUrl = useMutation({
    mutationFn: ({
      url,
      token,
      configToken,
    }: {
      url: string;
      token: string;
      configToken: string;
    }) => api.deleteTargetingUrls({ url }, token, configToken),
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["url-deleted-message", { url: response.body.message }],
        response.body.message,
      );
    },
  });
  const bulkUpdateDomain = useMutation({
    mutationFn: ({ publishers, changes, notes }: BulkDomainsProperties) =>
      api.bulkUpdateDomains({ publishers, changes, notes }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  const bulkDeleteDomain = useMutation({
    mutationFn: ({ publishers, changes, notes }: BulkDomainsProperties) =>
      api.bulkDeleteDomains({ publishers, changes, notes }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["domain-configs"] });
    },
  });
  return {
    domains: domainsQuery.data,
    query: domainsQuery,
    create: createMutation.mutate,
    delete: deleteMutation.mutate,
    updateConfig: updateConfigMutation.mutate,
    updateDomain: updateDomain.mutate,
    saveTargetingUrls: saveTargetingUrls.mutate,
    deleteTargetingUrl: deleteTargetingUrl.mutate,
    bulkUpdateDomain: bulkUpdateDomain.mutate,
    bulkDeleteDomain: bulkDeleteDomain.mutate,
  };
}
