import React, { SyntheticEvent } from "react";
import KrgDatePicker, {
  DateRange,
} from "@kargo/shared-components.krg-date-picker";

type Props = {
  isEnabled?: boolean;
  value?: DateRange | undefined;
  onDateRangeChange?: (date: DateRange | undefined) => any;
  onDateClear?: () => any;
  onClick?: (ev?: SyntheticEvent) => any;
};
export function DatePicker({
  isEnabled,
  value,
  onDateRangeChange,
  onDateClear,
  onClick,
}: Props) {
  return (
    <KrgDatePicker
      dateRange={value}
      inputVariant={KrgDatePicker.VARIANT_ENUM.outlined}
      placeholder="Date Added Range"
      isRange
      isEnabled={isEnabled}
      onDateRangeChange={onDateRangeChange}
      onDateClear={onDateClear}
      onClick={onClick}
    />
  );
}
export type { DateRange };
