import { useCallback, useMemo } from "react";
import {
  useMutation,
  useInfiniteQuery,
  useQueryClient,
  InfiniteData,
} from "react-query";
import { DateRange } from "@kargo/shared-components.krg-date-picker";
import useAPI from "../api/useAPI";
import { VideoInput } from "../api/types";
import { MEDIAS_FETCH_LIMIT } from "../constants/constants";
import { UploadActionEnums } from "../@types/upload.types";
import { VIDEO_CREATE_MUTATION_KEY } from "../constants/provider.states";

export type Query = {
  category?: string | string[];
  page?: number;
  limit?: number;
  skip?: number;
  text?: string;
  tags?: string[];
  group?: string;
  imported_from?: string;
  date_range?: DateRange | undefined | string;
  duration?: number[] | string;
  [key: string]: any;
};

function getQueryParams({
  page = 0,
  limit = MEDIAS_FETCH_LIMIT,
  skip,
  text,
  category,
  group,
  imported_from,
  duration,
  date_range,
  ...params
}: Query) {
  const query: Query = {
    count: true,
    ...(imported_from && { imported_from }),
    ...(group && { group }),
    skip: skip || limit * page,
    limit,
    ...(category && { category }),
    sort: JSON.stringify({ created_at: "desc" }),
    ...(text && { search_text: text }),
    ...params,
  };
  if (duration && typeof duration !== "string") {
    const durationObject: any = {};
    if (duration.includes(-1)) durationObject.min = 601;
    const maxValue = Math.max(...duration);
    if (maxValue && maxValue !== -1) durationObject.max = maxValue;
    query.duration = Object.keys(durationObject).length !== 0 && durationObject;
  }
  if (date_range) {
    const { from, to } = date_range as DateRange;
    query.created_range = JSON.stringify({
      start: from ? from.getTime() : undefined,
      end: to ? new Date(to).setDate(new Date(to).getDate() + 1) : undefined,
    });
  }
  return query;
}

export default function useVideos(query: Query) {
  const queryClient = useQueryClient();
  const { api, didLoad } = useAPI();
  const fetchVideos = useCallback(
    (params) => api.Media.index(getQueryParams(params)),
    [api],
  );
  const stableQuery = useMemo(() => query, [JSON.stringify(query)]);
  const videosQuery = useInfiniteQuery({
    queryKey: ["videos", stableQuery],
    queryFn: ({ pageParam = 0 }) =>
      fetchVideos({ page: pageParam, ...stableQuery }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.medias?.length < (stableQuery.limit || MEDIAS_FETCH_LIMIT))
        return undefined;
      return pages.length;
    },
    enabled: didLoad,
    onSuccess: (data) => {
      data.pages
        .reduce((videos, page) => videos.concat(page.medias), [])
        .forEach((video) =>
          queryClient.setQueryData(["video", video.token], video),
        );
    },
    staleTime: Infinity,
  });
  const createMutation = useMutation({
    mutationKey: `${VIDEO_CREATE_MUTATION_KEY}`,
    mutationFn: ({
      file,
      data,
      index,
      abortController,
      uploadDispatcher,
      image,
      publisherUsername,
      onCreate,
    }: {
      file: File;
      data?: any;
      image?: File;
      index?: number;
      abortController?: AbortController;
      uploadDispatcher?: (type: UploadActionEnums, payload?: any) => void;
      publisherUsername?: string;
      onCreate?: (
        data: VideoInput & { token: string },
        signedURLs: { url: string; imageURL: string },
      ) => any;
    }) =>
      api.createVideo({
        data: {
          ...data,
          media_info: {
            original_file_name: file.name,
            original_extension: file.name.split(".").pop(),
            ...data?.media_info,
          },
          media_type: "video",
          provider: "cms",
        },
        file,
        image,
        abortController,
        publisherUsername,
        index,
        uploadDispatcher,
        onCreate: (d, signedURLs) => {
          uploadDispatcher(UploadActionEnums.VIDEO_CREATION_STARTED, {
            index,
            file,
            title: file.name.split(".")[0],
          });
          if (onCreate) onCreate(d, signedURLs);
        },
        onProgress: (token, e) => {
          const percentage = (e.loaded / e.total) * 100;
          if (typeof uploadDispatcher === "function" && percentage > 0) {
            uploadDispatcher(UploadActionEnums.VIDEO_UPLOAD_PROGRESS, {
              index,
              progress: percentage,
            });
          }
        },
      }),
    onSuccess: (data) => {
      queryClient.setQueryData<Record<string, any>>(
        ["video", data.token],
        (oldData) => {
          const videoData = { ...oldData, ...data };
          if (!oldData) {
            queryClient.setQueryData<InfiniteData<{ medias: any[] }>>(
              ["videos", {}],
              (videos) => {
                if (videos) videos.pages[0].medias.unshift(videoData);
                return videos;
              },
            );
          }
          return videoData;
        },
      );
    },
  });
  const videos =
    videosQuery.data?.pages.reduce((arr, page) => {
      const uniqueTokens = new Set(arr.map((video) => video.token)); // Set of existing tokens
      page.medias.forEach((newVideo) => {
        if (!uniqueTokens.has(newVideo.token)) {
          // Add video if token is unique
          uniqueTokens.add(newVideo.token);
          arr.push(newVideo);
        }
      });
      return arr;
    }, []) || [];

  const totalCount = videosQuery.data?.pages[0]?.count || 0;

  return {
    query: videosQuery,
    isFetching: videosQuery.isFetching,
    totalCount,
    fetchNextPage: videosQuery.fetchNextPage,
    hasNextPage: videosQuery.hasNextPage,
    videos,
    api: {
      create: createMutation.mutate,
    },
    mutations: {
      createMutation,
    },
  };
}
