import React, { useRef } from "react";
import { Box, FormControl, FormGroup, InputLabel } from "@mui/material";
import KrgChip, {
  THEME_ENUM,
  TYPE_ENUM,
} from "@kargo/shared-components.krg-chip";
import { ChipData } from "@kargo/shared-components.krg-chip/dist/shared/interfaces";

import { handleAddTag, handleDeleteTag } from "../../context/form/form.actions";
import InfoPopover from "../InfoPopover";
import { FormInputFields } from "../../@types/form.types";
import { useFormContext } from "../../context/form/form.provider";
import WrongFieldComponent from "./WrongFieldComponent";

const TagFieldComponent = ({ fieldKey }: { fieldKey: string }) => {
  const [{ errors, formInputFields }, dispatch] = useFormContext();
  const tags = formInputFields[fieldKey] as FormInputFields;
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldError = errors[fieldKey];

  if (!tags) return <WrongFieldComponent fieldKey={fieldKey} />;

  return (
    <FormGroup
      className={`form-group-container ${fieldError ? "error-field-group" : ""}`}
    >
      <InputLabel>
        {tags.label && tags.label}
        {tags.popoverText && <InfoPopover text={tags.popoverText} />}
      </InputLabel>
      <FormControl error={!!fieldError}>
        <Box
          className="chip-container"
          onClick={() => {
            inputRef.current?.focus();
          }}
        >
          {tags.value &&
            (tags.value as ChipData[]).map((t: ChipData) => (
              <KrgChip
                key={`chip-${t.id}`}
                className="tags-chip"
                theme={THEME_ENUM.v2}
                type={TYPE_ENUM.gray}
                data={{ text: t.text, id: t.id }}
                onDelete={() => handleDeleteTag(dispatch, t, tags)}
              />
            ))}
          <input
            type="text"
            onKeyDown={(ev) => handleAddTag(dispatch, ev, tags, inputRef)}
            placeholder="Add a tag"
            ref={inputRef}
          />
        </Box>
      </FormControl>
    </FormGroup>
  );
};

export default TagFieldComponent;
