import { TYPE_ENUM } from "@kargo/shared-components.krg-snackbar";
import { Video } from "../api/types";
import { Playlist } from "./playlist.types";

export type SingleMediaEditForm = {
  token: string;
  url: string;
  title: string;
  tags: string[];
  categories: string[];
  description?: string;
};

export type AutocompleteProps = {
  id: number;
  name: string;
};

export type ActionType = "delete" | "restore";

export type PlaylistActionType = "delete" | "copy";

export type VideoActionState = {
  isOpen: boolean;
  type: ActionType;
  video: Video | null;
};

export type PlaylistActionState = {
  isOpen: boolean;
  type: PlaylistActionType;
  playlist: Playlist | null;
};

export type SnackbarState = {
  type: TYPE_ENUM;
  isOpen: boolean;
  message: string;
};

export enum MediaState {
  // Only Frontend states
  UPLOADING = "uploading",
  ABORTED = "aborted",

  // API states
  EMPTY = "empty",
  PROCESSING = "processing",
  READY = "ready",
  UPLOADED = "uploaded",
  FAILED = "failed",
  SOFT_DELETED = "soft_deleted",
  HARD_DELETED = "hard_deleted",
  DRAFT = "draft",
}

export enum BulkEditType {
  TAGS = "tags",
  CATEGORY = "category",
}

export type UploadMediaState = {
  file: File;
  index: number;
  progress: number;
  uploading: boolean;
  state?: MediaState;
  token?: string;
  title?: string;
  category?: string;
  tags?: string[];
};
