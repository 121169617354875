import React from "react";
import { Delete, Edit, ContentCopy } from "@mui/icons-material";
import { Playlist } from "../../api/types";
// import { PlaylistActionType } from "../../@types/media.types";

interface PlaylistActionsProps {
  playlist: Playlist;
  // onActionClick?: (type: PlaylistActionType, playlist: Playlist) => void;
  readonly onEditActionClick?: (token: string) => void;
}

const iconStyle = {
  ml: "10px",
  "&:hover": {
    cursor: "pointer",
    color: "#03f",
  },
};

export default function PlaylistActions({
  playlist,
  // onActionClick,
  onEditActionClick,
}: PlaylistActionsProps) {
  return (
    <>
      <Edit sx={iconStyle} onClick={() => onEditActionClick(playlist.token)} />
      <ContentCopy
        sx={iconStyle}
        // onClick={() => onActionClick("copy", playlist)}
      />
      <Delete
        sx={iconStyle}
        // onClick={() => onActionClick("delete", playlist)}
      />
    </>
  );
}
