import React from "react";

import { FormInputTypeAttribute } from "../../@types/form.types";
import TagFieldComponent from "./TagFieldComponent";
import SelectFieldComponent from "./SelectFieldComponent";
import TextInputFieldComponent from "./TextInputFieldComponent";

const FormFieldRenderer = ({
  name,
  type,
}: {
  name: string;
  type: FormInputTypeAttribute;
}) => {
  switch (type) {
    case `text`:
      return <TextInputFieldComponent fieldKey={name} />;
    case `select`:
      return <SelectFieldComponent fieldKey={name} />;
    case `chip`:
      return <TagFieldComponent fieldKey={name} />;
    default:
      // As there could be some inputs which is with predefined component, default will be empty for them
      return <></>;
  }
};

export default FormFieldRenderer;
