import React from "react";
import LoadingCircle from "../../components/LoadingCircle";

export default function MediaLoader() {
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 259px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingCircle />
    </div>
  );
}
