import React, { FormEvent, MouseEvent, useEffect, useState } from "react";
import { Alert, Box, Container, Grid, Stack, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { VARIANT_ENUM } from "@kargo/shared-components.krg-text-input";
import Delete from "@mui/icons-material/Delete";
import KrgButton from "@kargo/shared-components.krg-button";

import { PATHS } from "../../../constants/paths";
import SinglePlaylistEditor from "./SinglePlaylistEditor";
import LoadingCircle from "../../../components/LoadingCircle";
import SinglePlaylistActionButtons from "./SinglePlaylistActionButtons";
import { Playlist, PlaylistCreationType } from "../../../@types/playlist.types";
import {
  submitForm,
  checkInitialFormValidation,
} from "../../../context/form/form.actions";
import ConfirmModal from "../../../components/Modals/ConfirmModal";
import { useFormContext } from "../../../context/form/form.provider";
import { FormActionsEnum, FormInitInputs } from "../../../@types/form.types";
import usePlaylist from "../../../hooks/usePlaylist";
import SinglePlaylistMediaList from "./SinglePlaylistMediaList";

type SinglePlaylistPageProps = {
  playlist: Playlist;
  token: string;
  creationType: PlaylistCreationType;
  initialFormState: FormInitInputs;
};

const SinglePlaylistPageContainer = ({
  token,
  initialFormState,
  creationType,
}: SinglePlaylistPageProps) => {
  const { push } = useHistory();
  const [
    { formInputFields, errors, data, showAllErrors, submitting, touched },
    dispatch,
  ] = useFormContext();
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();
  const {
    mutations: { updateMutation, deleteMutation },
  } = usePlaylist(token);
  const { mutate } = updateMutation;

  useEffect(() => {
    if (deleteMutation.isSuccess) {
      queryClient
        .resetQueries(["playlist"])
        .then(() => push(PATHS.MEDIA_GALLERY_PLAYLISTS));
    }
  }, [deleteMutation.isSuccess]);

  useEffect(() => {
    if (!touched && formInputFields) {
      checkInitialFormValidation(dispatch, formInputFields);
    }
  }, []);

  const submitHandler = async (ev: FormEvent | MouseEvent) => {
    ev.preventDefault();
    submitForm(dispatch, errors, data, mutate);
  };

  return (
    <Container className="page-container">
      {/* Confirm Modal */}
      <ConfirmModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmitClick={() => deleteMutation.mutate(token)}
        header="Deleting Playlist"
        body="Are you sure you want to delete this playlist?"
        submitButtonText="Delete"
        cancelButtonText="Cancel"
      />

      <Box className="page-title-container">
        <Typography variant="h1" className="page-title">
          {formInputFields.title?.value?.toString()}
        </Typography>
      </Box>

      <SinglePlaylistActionButtons
        initialFormState={initialFormState}
        submitHandler={submitHandler}
      />

      {Object.keys(errors).length > 0 && showAllErrors && (
        <Alert
          severity="error"
          sx={{ marginBottom: "20px" }}
          onClose={() => dispatch({ type: FormActionsEnum.CLOSE_FORM_ERROR })}
        >
          {Object.keys(errors).map((errKey) =>
            errors[errKey].map((err: string) => (
              <Typography key={`alert-${err}`}>{err}</Typography>
            )),
          )}
        </Alert>
      )}

      <Stack>
        <Grid container spacing={4}>
          {/* Form Fields */}
          <Grid
            item
            xs={12}
            md={5}
            order={{ xs: 2, md: 1 }}
            sx={{ paddingLeft: 0 }}
          >
            {submitting ? (
              <LoadingCircle />
            ) : (
              <form onSubmit={submitHandler}>
                <SinglePlaylistEditor />
              </form>
            )}
          </Grid>
          {/* Media List Fields */}
          <Grid
            item
            xs={12}
            md={7}
            order={{ xs: 1, md: 2 }}
            sx={{ marginBottom: "25px" }}
          >
            <SinglePlaylistMediaList
              token={token}
              creationType={creationType}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box>
        <KrgButton
          id="delete-playlist"
          className="form-delete-button"
          text="Delete Playlist"
          onClick={() => setShowModal(true)}
          variant={VARIANT_ENUM.outlined}
          isSquaredBorder
          startIcon={<Delete />}
          style={{
            marginTop: "17px",
            border: "none",
            paddingLeft: 0,
          }}
        />
      </Box>
    </Container>
  );
};

export default SinglePlaylistPageContainer;
