import React, { ReactNode } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
} from "@mui/material";
import _ from "lodash";
import { makeStyles } from "@mui/styles";
import KrgCheckbox from "@kargo/shared-components.krg-checkbox";
import { VideosCollection } from "../../components/VideosCollection";
import { ArrowDown } from "../../components/Icons";
import useVideos from "../../hooks/useVideos";
import MenuButton from "../../components/Menu/MenuButton";
import "./styles.scss";
import VideosTable from "../../components/VideosTable";
import LoadingCircle from "../../components/LoadingCircle";
import ViewSelector from "./ViewSelector";
import useShiftKeyListener from "../../hooks/useShiftKeyListener";
import { getRangeSelection } from "../../helpers/videos";
import useSnackbar from "../../hooks/useSnackbar";

const useStyles = makeStyles({
  summary: {
    padding: 0,
    borderBottom: "1px solid black",
    minHeight: "47px",
    "& .MuiAccordionSummary-content": {
      transform: "translate(10px)",
      justifyContent: "space-between",
      margin: 0,
    },
    "&.Mui-expanded": {
      minHeight: "47px",
      "& $arrow": {
        transform: "rotate(180deg)",
      },
    },
  },
  arrow: {
    transition: "transform .3s ease-in-out",
    width: "10px",
    marginLeft: "5px",
    filter: "brightness(0)",
  },
  details: {
    maxHeight: "520px",
    overflowY: "scroll",
    padding: "24px 0",
  },
  input: {
    height: "37px",
  },
});

let prevSelected;
type Props = {
  menuButtonProps?: React.ComponentProps<typeof MenuButton>;
  onChangeSelected: (selected: string[]) => any;
  query?: any;
  selected: string[];
  title?: string;
  subtitle?: string;
  state?: ReactNode;
};
export default function VideosAccordion({
  menuButtonProps,
  onChangeSelected,
  query,
  selected,
  title,
  subtitle,
  state,
}: Props) {
  const [viewType, setViewType] = React.useState<"card" | "table">("card");
  const [expanded, setExpanded] = React.useState(true);
  const [selectedAll, setSelectedAll] = React.useState(false);
  const [shiftSelectionState, setShiftSelectionState] = React.useState(false);
  const { snackbarWarning } = useSnackbar();
  useShiftKeyListener(setShiftSelectionState, [selected]);
  const classes = useStyles();
  const {
    videos,
    query: { fetchNextPage, hasNextPage, isFetching, isFetchingNextPage },
  } = useVideos(query);
  const handleToggleAll = (checked: boolean) => {
    if (videos.length === 0) {
      setSelectedAll(checked);
      if (checked) snackbarWarning("MRSS Feed selected is Empty");
      return;
    }
    if (!checked)
      onChangeSelected(
        selected.filter((s) => videos.find((v) => v.token === s) === undefined),
      );
    else
      onChangeSelected(
        _.union(
          selected,
          videos.map((v) => v.token),
        ),
      );
  };
  const handleVideoToggle = (video: string, isSelected: boolean) => {
    const index = selected.indexOf(video);
    const videosToken = videos.map((v) => v.token);
    const selections = getRangeSelection(
      video,
      videosToken,
      shiftSelectionState,
      prevSelected,
    );
    let newSelected;
    if (isSelected && index === -1) {
      newSelected = [...selected, ...selections];
    } else if (!isSelected && index > -1) {
      newSelected = selected.filter((i) => !selections.includes(i));
    }
    prevSelected = shiftSelectionState ? undefined : video;
    onChangeSelected(newSelected);
  };
  React.useEffect(() => {
    const handleScroll = () => {
      if (isFetchingNextPage || !hasNextPage) return;
      const { scrollTop, offsetHeight: documentHeight } =
        document.documentElement;
      const windowHeight = window.innerHeight;
      if (documentHeight - scrollTop <= 10 * windowHeight) fetchNextPage();
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetchingNextPage, hasNextPage]);

  return (
    <Accordion
      elevation={0}
      expanded={expanded}
      onChange={() => setExpanded((oldValue) => !oldValue)}
      sx={{ "&:before": { display: "none" } }}
    >
      <AccordionSummary className={classes.summary} sx={{ marginLeft: "8px" }}>
        <Box
          display="flex"
          alignItems="center"
          gap="16px"
          fontSize="14px"
          fontWeight="500"
          color="black"
        >
          <KrgCheckbox
            theme={KrgCheckbox.THEME_ENUM.v2}
            isChecked={
              videos.length > 0
                ? videos.every((v) => selected.includes(v.token))
                : selectedAll
            }
            onToggle={handleToggleAll}
          />
          <Box>
            <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
              {title}
            </Typography>
            {subtitle && (
              <Typography sx={{ fontSize: "10px" }}>{subtitle}</Typography>
            )}
          </Box>
          <ArrowDown className={classes.arrow} alt="expand icon" />
          {state}
        </Box>
        <Box display="flex" alignItems="center">
          <ViewSelector onViewChange={setViewType} viewType={viewType} />
          {menuButtonProps && <MenuButton {...menuButtonProps} />}
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.details}>
        {videos.length > 0 &&
          (viewType === "card" ? (
            <VideosCollection
              videos={videos}
              selected={selected}
              isSelectable
              onVideoToggle={handleVideoToggle}
            />
          ) : (
            <VideosTable
              videos={videos}
              selectedVideosToken={selected}
              onToggleAll={handleToggleAll}
              // TODO: need to fix ts error, setting single type for onVideoToggle arguments, some cases it's video array, in other cases it's tokens array
              onVideoToggle={handleVideoToggle}
            />
          ))}
        {isFetching && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <LoadingCircle style={{ width: "50px" }} />
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
