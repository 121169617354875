import { DateRange } from "@kargo/shared-components.krg-date-picker";
import { Video } from "../api/types";

export const enum PlaylistCreationEnum {
  TAG = "tag",
  NONE = "none",
  CATEGORY = "category",
}
export type PlaylistCreationType =
  (typeof PlaylistCreationEnum)[keyof typeof PlaylistCreationEnum];
export type PlaylistSortTypes = "newest" | "oldest" | "shuffle";

type SortType = "asc" | "desc" | "ascending" | "descending" | 1 | -1;
// sort({ fieldOne: 'asc', secondField: -1 }) OR sort('fieldOne -secondField');
type Sort = string | { [key: string]: SortType };

export type PlaylistTagInput = {
  title?: string;
  key?: string;
  tags?: string[];
  excludedTags?: string[];
  username?: string;
  sort: "oldest" | "newest" | "shuffle";
};
export type PlaylistCategoryInput = {
  title?: string;
  key?: string;
  categories?: string[];
  username?: string;
  sort: "oldest" | "newest" | "shuffle";
};

export type PlaylistInput = {
  title?: string;
  key?: string;
  medias?: string[];
  username?: string;
  shuffle?: boolean;
  updated_at?: string;
};

export type Playlist = PlaylistInput & {
  token: string;
  medias: string[];
};

export type PlaylistResponse = {
  title: string;
  shuffle: boolean;
  medias: Video[];
  token: string;
  created_at: string;
  created_by: string;
  tags: string[];
  categories: string[];
  excludedTags: string[];
  type: PlaylistCreationType;
  sortType: PlaylistSortTypes;
};

export type PlayableStream = {
  url: string;
  token: string;
  type: "default" | "hls";
  image_url: string;
  media_info: {
    duration: number;
    size: number;
    width: number;
    height: number;
    label: string;
  };
};
export type PlaylistStreamableMediaInfo = {
  category: string;
  description: string;
  key: string;
  language: string;
  provider: string;
  provider_data: {
    has_imported_subtitle: boolean;
  };
  tags: string[];
  title: string;
  updated_at: string;
};
export type PlaylistStreamableMedia = {
  media_token: string;
  media_info: PlaylistStreamableMediaInfo;
  playable_streams: PlayableStream[];
};
export type PlaylistStreamableMediaResponse = {
  playlist_token: string;
  playlist_info: {
    title: string;
  };
  medias: PlaylistStreamableMedia[];
};

export type PlaylistSingleMediaRow = PlaylistStreamableMedia & {
  id: number | string; // very required for KrgDataTable row key
  index?: number;
  thumbnail: string;
  title: string;
  duration: string;
  dateAdded: string;
  status?: string;
};

export type PlaylistFilter = {
  asAdmin: boolean;
  title?: string;
  type?: string[];
  page?: number;
  limit?: number;
  skip?: number;
  sort?: Sort;
  date_range?: DateRange | undefined | string;
};

export type PlaylistQuery = {
  asAdmin: boolean;
  title?: string;
  type?: string[];
  page?: number;
  limit?: number;
  skip?: number;
  sort?: string;
  created_range?: string;
};
